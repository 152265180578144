<template>
  <div class="sidepage-container">
    <SideTitle :title="'Regular Scan Update'">
      <template v-slot:BtnGroup>
        <v-btn color="primary" depressed>
          Confirm
        </v-btn>
      </template>
    </SideTitle>
    <v-container fluid class="sidepage-body">
      <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
        <v-text-field label="Name" value="" hide-details="auto" class="mb-3"></v-text-field>
        <v-select label="Schedule"></v-select>
        <!--Schedule 옵션에 따라 나오는 폼 -->
        <div class="mb-3">
          <v-btn-toggle v-model="toggle_exclusive" mandatory tile color="primary" class="schedule-btn-toggle">
            <v-btn small>Sun</v-btn>
            <v-btn small>Mon</v-btn>
            <v-btn small>Tue</v-btn>
            <v-btn small>Wed</v-btn>
            <v-btn small>Thu</v-btn>
            <v-btn small>Fri</v-btn>
            <v-btn small>Sat</v-btn>
          </v-btn-toggle>
        </div>
        <div class="mb-3">
          <v-btn-toggle v-model="toggle_exclusive" mandatory tile color="primary" class="schedule-btn-toggle">
            <v-btn small>1</v-btn>
            <v-btn small>2</v-btn>
            <v-btn small>3</v-btn>
            <v-btn small>4</v-btn>
            <v-btn small>5</v-btn>
            <v-btn small>6</v-btn>
            <v-btn small>7</v-btn>
            <v-btn small>8</v-btn>
            <v-btn small>9</v-btn>
            <v-btn small>10</v-btn>
            <v-btn small>11</v-btn>
            <v-btn small>12</v-btn>
            <v-btn small>13</v-btn>
            <v-btn small>14</v-btn>
            <v-btn small>15</v-btn>
            <v-btn small>16</v-btn>
            <v-btn small>17</v-btn>
            <v-btn small>18</v-btn>
            <v-btn small>19</v-btn>
            <v-btn small>20</v-btn>
            <v-btn small>21</v-btn>
            <v-btn small>22</v-btn>
            <v-btn small>23</v-btn>
            <v-btn small>24</v-btn>
            <v-btn small>25</v-btn>
            <v-btn small>26</v-btn>
            <v-btn small>27</v-btn>
            <v-btn small>28</v-btn>
            <v-btn small>29</v-btn>
            <v-btn small>30</v-btn>
            <v-btn small>31</v-btn>
          </v-btn-toggle>
        </div>
        <div class="mb-3">
          <datePicker />
        </div>
        <!--Schedule 옵션에 따라 나오는 폼 -->
        <div class="mb-3">
          <timePicker />
        </div>
        <TreeView />
      </v-form>
    </v-container>
  </div>
</template>
  
<script>
import SideTitle from '@/components/SideTitle.vue'
import timePicker from '@/components/timePicker.vue';
import datePicker from '@/components/datePicker.vue';
import TreeView from '@/components/TreeView.vue';

export default {
  name: 'RegularScanPage',
  components: {
    SideTitle, timePicker, datePicker, TreeView
  },
  data: () => ({
  }),
  methods: {
  },
}
</script>

<style lang="scss">
.schedule-btn-toggle.v-btn-toggle {
  display: flex;
  flex-wrap: wrap;
}

.schedule-btn-toggle.v-btn-toggle>.v-btn.v-btn {
  flex: 1 0 0%;
  width: 14.285%;
  max-width: 14.285%;
  padding: 0 10px;
}

.schedule-btn-toggle .v-btn:not(.v-btn--round).v-size--small {
  min-width: 14.285%;
}
</style>