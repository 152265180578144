// @ is an alias to /src
import locationDetail from '@/views/rightContent/locationDetail.vue'
import accountManagementAdd from '@/views/rightContent/accountManagementAdd.vue'
import accountManagementUpdate from '@/views/rightContent/accountManagementUpdate.vue'
import regularScan from '@/views/mission/regularScan/regularScan.vue'
import regularScanUpdate from '@/views/mission/regularScan/regularScanUpdate.vue'
import adHocScan from '@/views/mission/adHocScan/adHocScan.vue'
import missionLog from '@/views/mission/missionLog/missionLog.vue'
import fleetStatus from '@/views/mission/fleetStatus/fleetStatus.vue'

export default {
  name: 'RightContentLayout',
  components: { 
    locationDetail, accountManagementAdd, accountManagementUpdate, regularScan, regularScanUpdate, adHocScan, missionLog, fleetStatus,
  },
  created() {
  },
  data: () => ({
  }),
}