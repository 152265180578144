<template>
  <v-autocomplete v-model="selectedRack" :items="racks" label="Rack/Bay/Location" multiple prepend-inner-icon="mdi-cube-outline"
    variant="outlined" hide-details="auto">
    <template v-slot:selection="{ item, racks }">
      <v-chip v-if="racks === 0">
        <span>{{ item }}</span>
      </v-chip>
      <span v-if="racks === 1" class="grey--text text-caption">
        (+{{ value.length - 1 }} others)
      </span>
    </template>

    <template v-slot:prepend-item>
      <TreeView />
    </template>
  </v-autocomplete>
</template>

<script>
import TreeView from '@/components/TreeView.vue';

export default {
  name: 'Tree',
  components: {
    TreeView
  },
  data: () => ({
    racks: [
      '104',
      '105',
      '106'
    ],
    selectedRack: ['104', '105'],
  }),

  computed: {
  },

  methods: {
  },
}
</script>

<style lang="scss">
.v-select-list .text-treeview-title {
  display: none;
}
</style>