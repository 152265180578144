// @ is an alias to /src

export default {
  name: 'RightLayout',
  components: { 
  },
  created() {
  },
  data: () => ({
  }),
}