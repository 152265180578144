export default {
    dateFormat: 'YYYY-MM-DD', // moment format

    title: {},

    label: {},

    btn: {},

    message: {},
};
