<template>
    <div class="grid-group">
        <v-card flat class="grow rounded-0">
            <!-- <button @click="getSelectedRows()">Get Selected Rows</button> -->
            <ag-grid-vue
                style="width: 100%; height: calc(100vh - 250px)"
                class="ag-theme-balham"
                :columnDefs="columnDefs"
                :rowData="rowData"
                :autoGroupColumnDef="autoGroupColumnDef"
                @grid-ready="onGridReady"
            >
                <!--class="ag-theme-balham-dark"-->
            </ag-grid-vue>
        </v-card>
    </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';

export default {
    name: 'agGrid',
    components: {
        AgGridVue,
    },
    data() {
        return {
            columnDefs: null,
            rowData: null,
            gridApi: null,
            columnApi: null,
            autoGroupColumnDef: {
                headerName: 'Model',
                field: 'model',
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    checkbox: true,
                },
            },
        };
    },
    beforeMount() {
        this.columnDefs = [
            { field: 'make', sortable: true, filter: true, checkboxSelection: true, rowGroup: true },
            { field: 'model', sortable: true, filter: true },
            { field: 'price', sortable: true, filter: true },
        ];

        fetch('https://www.ag-grid.com/example-assets/row-data.json')
            .then((result) => result.json())
            .then((rowData) => (this.rowData = rowData));
    },
    methods: {
        onGridReady(params) {
            this.gridApi = params.api;
            this.columnApi = params.columnApi;
        },
        getSelectedRows() {
            const selectedNodes = this.gridApi.getSelectedNodes();
            const selectedData = selectedNodes.map((node) => node.data);
            const selectedDataStringPresentation = selectedData.map((node) => `${node.make} ${node.model}`).join(', ');
            alert(`Selected nodes: ${selectedDataStringPresentation}`);
        },
    },
};
</script>
<style lang="scss">
// @import "~ag-grid-community/dist/styles/ag-grid.css";
// //@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
// //@import "~ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
// @import "~ag-grid-community/dist/styles/ag-theme-balham.css";
// @import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
</style>
