<template>
  <div class="grid-filter">
    <v-row no-gutters class="align-center mb-1">
      <v-col cols="auto">
        <div class="d-flex align-center">
          <slot name="FilterLeft"></slot>
        </div>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <div class="d-flex align-center">
          <slot name="FilterRight"></slot>
          <columnSetting />
          <FileDownload />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import columnSetting from '@/components/agGrid/columnSetting.vue'
  import FileDownload from '@/components/agGrid/FileDownload.vue'

export default {
  name: 'gridFilters',
  components: {
    columnSetting, FileDownload
  },
  data() {
    return {
      columnSettings: [],
    }
  },
};
</script>