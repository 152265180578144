export default {
    dateFormat: 'MM/DD/YYYY', // moment format
    title: {},

    label: {},

    btn: {},

    message: {
        copyright: '2023 B GARAGE',
        loggedOut: 'You have been Logged out.', // '로그아웃되었습니다.'
        errorOccurred: 'An error has occurred.', // '에러가 발생했습니다.'
        canceledReqeust: 'All pending network requests have been cancelled.', //'응답 대기중인 모든 네트워크 요청을 취소하였습니다.'
    },
};
