// @ is an alias to /src
export default {
  name: 'DashboardNoti',
  components: {
  },
  data: () => ({
    messages: [
      {
        from: 'Regular Scan completed',
        message: 'Regular scan started by <span color="lime darken-4">BATCH</span> completed. Out of 34 locations, 23 scanned, 0 discrepancies and 4 unidentified locations found.',
        time: '04/20/20 10:42',
        color: 'success',
      },
      {
        from: 'Mission Scan started',
        message: 'Reqular scan started by <span color="lime darken-4">BATCH</span>',
        time: '04/20/20 10:42',
        color: 'error',
      },
    ],
  }),
}