<template>
  <div class="sidepage-container">
    <SideTitle :title="'Update'">
      <template v-slot:BtnGroup>
        <v-btn color="primary" depressed>
          Confirm
        </v-btn>
      </template>
    </SideTitle>
    <v-container fluid class="sidepage-body">
      <v-form ref="form" v-model="valid" lazy-validation class="pa-3">
        <v-text-field label="Name" value="" hide-details="auto" readonly class="mb-3"></v-text-field>
        <v-select label="Role"></v-select>
        <v-text-field label="ID" value="" hide-details="auto" readonly class="mb-3"></v-text-field>
        <v-text-field label="Password" value="" hide-details="auto" readonly class="mb-3"></v-text-field>
        <v-text-field label="Password confirmation" value="" hide-details="auto" readonly class="mb-3">
        </v-text-field>
        <v-text-field label="Email" value="" hide-details="auto" readonly class="mb-3"></v-text-field>
      </v-form>
    </v-container>
  </div>
</template>
  
<script>
import SideTitle from '@/components/SideTitle.vue'
import locationDetailGallery from './locationDetailGallery.vue'

export default {
  name: 'RegularScanPage',
  components: {
    SideTitle, locationDetailGallery
  },
  data: () => ({
  }),
  methods: {
  },
}
</script>
  