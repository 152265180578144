import router from '@/router';
import axios from 'axios';
import store from '@/store';
import Vue from 'vue';
import i18n from '@/i18n/';
// import vue from '@/main.js';

const instance = axios.create({
    // baseURL: process.env.VUE_APP_API,
    // baseURL: 'http://localhost:8080',
    baseURL: '/api',
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
});

// console.log(instance.interceptors.request);
// 요청 인터셉터 추가
instance.interceptors.request.use(
    function (config) {
        // 요청이 전달되기 전에 작업 수행
        // config.headers['X-Requested-With'] = 'XMLHttpRequest';

        // let setBwSpinnerVisible = sync('spinner/visible').set.bind(main);
        // let getBwSpinnerVisible = get('spinner/visible').bind(main);

        // setBwSpinnerVisible(true);

        // console.log(getBwSpinnerVisible());
        return config;
    },
    function (error) {
        // 요청 오류가 있는 경우 작업 수행
        return Promise.reject(error);
    },
);

// 응답 인터셉터 추가
instance.interceptors.response.use(
    function (response) {
        // 2xx 범위에 있는 상태 코드는 이 함수를 트리거
        // 응답 데이터가 있는 작업 수행
        return response;
    },
    function (error) {
        // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거
        // 응답 오류가 있는 작업 수행
        if (axios.isCancel(error)) {
        } else if (error.response && error.response.status) {
            //공통 부분에서 에러처리를 할지 여부
            if (error.config.responseCommonError !== false) {
                switch (error.response.status) {
                    // status code가 401인 경우 `logout`을 커밋하고 `/login` 페이지로 리다이렉트
                    case 401:
                        console.error(error);

                        store.commit('auth/loginUser', null); // store에서 loginUser 정보 초기화
                        setTimeout(() => {
                            router.push('/').catch(() => {});
                        });

                        Vue.$toast.warning(i18n.t('common.message.loggedOut')); // 로그아웃되었습니다.

                        // return new Promise(() => {}); // 이행되지 않는 Promise를 반환하여 Promise Chaining 끊어주기
                        break;
                    default:
                        console.error(error);

                        Vue.$toast.error(i18n.t('common.message.errorOccurred')); // 에러가 발생했습니다.
                }
            }
        }

        return Promise.reject(error);
    },
);

export default instance;
