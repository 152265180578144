<template>
    <!-- v-bind="$attrs" => 부모 Element에서 지정한 속성을 그대로 기술 -->
    <!-- v-on="$listeners" => 부모 Element에서 지정한 이벤트를 기술 -->
    <!-- v-if="isVisible" => 버튼의 권한에 따라 화면에 표시할지 여부 -->
    <v-btn v-bind="{ ...$props, ...$attrs }" v-on="$listeners" v-if="visible">
        <slot />
    </v-btn>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
    name: 'BwBtn',
    inheritAttrs: false, // 부모 Component에서 지정한 Element 속성을 무시 - vue 파일의 다음 구분 참조(v-bind="$attrs", v-on="$listeners")
    props: {
        /**
         * 버튼 권한
         * 예: 사용자의 페이지에 대한 권한이 Readonly인데 버튼은 Update 권한이 있어야만 한다면 화면에 표시되지 않습니다.
         */
        btnAuthority: {
            type: String,
            default: 'R', // R(readonly), C(create), U(update), D(delete), S(save - create, update, delete), 'CRUD'처럼 조합 가능
        },
    }, // end props

    beforeCreate() {}, // end beforeCreate
    created() {}, // end created
    beforeMounte() {}, //end beforeMounte
    mounted() {}, // end mounted
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    beforeDestroy() {}, // end beforeDestroy
    destroyed() {}, // end destroyed

    computed: {
        ...get('authority', ['pageAuthority']),
        /**
         * 화면 표시 여부
         * 사용자의 페이지에 대한 권한 여부에 따라 보이기/숨기기 를 판단합니다.
         *
         * @returns {boolean} 화면 표시 여부
         */
        visible: function () {
            let visible = false;
            let btnAuthority = this.btnAuthority;
            let pageAuthority = this.pageAuthority;

            if (btnAuthority.includes('C')) {
                visible = !!(pageAuthority.includes('C') || pageAuthority.includes('S'));
            } else if (btnAuthority.includes('U')) {
                visible = !!(pageAuthority.includes('U') || pageAuthority.includes('S'));
            } else if (btnAuthority.includes('D')) {
                visible = !!(pageAuthority.includes('D') || pageAuthority.includes('S'));
            } else if (btnAuthority.includes('S')) {
                visible = !!pageAuthority.includes('S');
            } else {
                // readonly
                visible = true;
            }

            return visible;
        },
    }, // end computed

    watch: {}, // end watch
    methods: {}, // end methods

    data: () => ({
        // pageAuthority: 'R', // 사용자에 대하여 화면에 부여된 권한 - 나중에는 Store 또는 메뉴에서 구해야 함
    }), // end data
};
</script>

<style lang="scss"></style>
