<template>
  <v-list subheader two-line style="height:163px;" class="overflow-auto">
    <Dialog>
      <template v-slot:dialogLinkGroup>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>09/19/2022 07:54:27</v-list-item-subtitle>
            <v-list-item-title>
              <v-chip label outlined small color="purple">Hardware</v-chip>
              <span class="pl-2">dialogdialogtesttesttesttesttesttesttesttesttesttest</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:dialogTitle>
        Bulletin
      </template>
      <template v-slot:dialogContent>
        <v-card-title>
          <v-chip label outlined small color="purple">Hardware</v-chip>
          <span class="pl-2"> This is test message</span> <span class="pl-2">09/14/2022 01:09:06</span>
        </v-card-title>
        <v-card-text class="py-3" style="max-height: 300px;">
          안녕하세요<br />
          내용은 여기에 표시 됩니다.<br />
          이미지 추가 테스트
        </v-card-text>
      </template>
      <template v-slot:dialogActionRight>
        <v-btn text @click="dialog = false">
          Close
        </v-btn>
      </template>
    </Dialog>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>09/19/2022 07:54:27</v-list-item-subtitle>
        <v-list-item-title>
          <v-chip label outlined small color="purple">Hardware</v-chip>
          <span class="pl-2">testtesttesttesttesttesttesttesttesttest</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>09/19/2022 07:54:27</v-list-item-subtitle>
        <v-list-item-title>
          <v-chip label outlined small color="purple">Hardware</v-chip>

          <span class="pl-2">testtesttesttesttesttesttesttesttesttest</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>09/19/2022 07:54:27</v-list-item-subtitle>
        <v-list-item-title>
          <v-chip label outlined small color="purple">Hardware</v-chip>
          <span class="pl-2">testtesttesttesttesttesttesttesttesttest</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import Dialog from '@/components/Dialog.vue';

export default {
  name: 'InventoriesPage',
  components: {
    Dialog
  },
  data() {
    return {
    }
  },
}
</script>