import http from './http';

export async function fetchBulletinsForMainPage(config) {
    return http.get('/bulletins/forMainPage', config).then((response) => {
        return response;
    });
}

// 테스트용
export async function fetchBulletinsForMainPage_error(config) {
    return http.get('/bulletins/forMainPage_error', config);
}

export async function fetchOlympicWinners() {
    return fetch('https://www.ag-grid.com/example-assets/olympic-winners.json').then((response) => response.json());
}
