import { get, sync } from 'vuex-pathify'

export default {
  name: 'Title',
  components: {
    
  },
  props: ['title'],
  data: () => ({    
    breadcrumbs: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/dashboard',
      },
      {
        text: 'Link',
        disabled: true,
        href: '/link',
      },
    ],
  }),
  computed: {
      ...sync('app', [
        'drawer', // 나중에 없애야 할 것으로 느껴짐
        'mini',
      ]),
      name: get('route/name'),
    },
}