<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date13"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="formatted"
                :label="isNil(label) ? 'Date' : label"
                :prepend-inner-icon="isNil(prependInnerIcon) ? 'mdi-calendar-blank-outline' : prependInnerIcon"
                hide-details="auto"
                v-on="on"
                readonly
                @click:clear="clear"
                v-bind="{ ...$props, ...$attrs }"
            ></v-text-field>
        </template>

        <v-date-picker v-model="strDate" v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="onClickCancel"> Cancel </v-btn>
            <v-btn text color="primary" @click="onClickOk"> OK </v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
    name: 'BwDatePicker',
    components: {},

    props: {
        label: {},
        value: {
            type: Number,
            default: 0,
        },
        format: {
            type: String,
        },
        prependInnerIcon: {},
        // clearVisible: {
        //     type: Boolean,
        //     default: false,
        // },
    },
    emits: ['update:value'],

    beforeCreate() {}, // end beforeCreate
    created() {}, // end created
    beforeMounte() {}, //end beforeMounte

    mounted() {
        this.strFormat = this.format;
        if (_.isEmpty(this.strFormat)) {
            this.strFormat = this.$t('common.dateFormat');
        }

        if (!this.value) {
            // YYYY-MM-DD
            // this.strDate = new Date().toISOString().replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, '$1-$2-$3');
            // this.date13 = Date.now();
        } else {
            this.date13 = this.value;
        }

        this.strDate = !_.isNil(this.date13) && this.date13 >= 0 ? moment(this.date13).format('YYYY-MM-DD') : ''; // 내부적으로는 YYYY-MM-DD를 사용
    }, // end mounted

    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    beforeDestroy() {}, // end beforeDestroy
    destroyed() {}, // end destroyed

    computed: {
        formatted() {
            return !_.isNil(this.date13) && this.date13 >= 0 ? moment(this.date13).format(this.strFormat) : '';
        },
    }, //end computed

    watch: {}, // end watch

    methods: {
        isNil(value) {
            return _.isNil(value);
        },

        onClickCancel() {
            this.menu = false;
            this.strDate = !_.isNil(this.date13) && this.date13 >= 0 ? moment(this.date13).format('YYYY-MM-DD') : ''; // 내부적으로는 YYYY-MM-DD를 사용
        },

        onClickOk() {
            this.date13 = moment(this.strDate).valueOf();
            this.$refs.menu.save(this.date13);
            this.$emit('update:value', this.date13);
        },

        clear() {
            this.strDate = '';
            this.onClickOk();
        },
    }, // end methods

    data: () => ({
        date13: -1,
        strDate: null, // v-date-picker의 v-model이 String임(format: YYYY-MM-DD)
        strFormat: null,
        menu: false,
    }),
};
</script>
