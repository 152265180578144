<template>
    <div class="sidepage-container">
        <SideTitle :title="'Mission Log'">
            <template v-slot:BtnGroup> </template>
        </SideTitle>
        <v-container fluid class="sidepage-body">
            <v-card flat>
                <v-card-text class="pt-1">
                    <div class="mt-2 mb-3">
                        <dateRangePicker />
                    </div>
                    <v-text-field prepend-inner-icon="mdi-magnify" label="Search" hide-details="auto"> </v-text-field>
                </v-card-text>

                <v-card-text class="py-0">
                    <ListFilters>
                        <template v-slot:FilterLeft>
                            <div class="text-total">Total 54</div>
                        </template>
                        <template v-slot:FilterRight>
                            <FileDownload />
                        </template>
                    </ListFilters>
                </v-card-text>
                <v-divider></v-divider>

                <v-list class="pa-0">
                    <v-list-item-group v-model="selected" active-class="mission-list-selected">
                        <template v-for="(item, index) in items">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.mission" class="text-caption"></v-list-item-title>
                                    <v-list-item-subtitle class="subtitle-2 text--primary mt-1" v-text="item.startdate"> </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-chip class="chip-list chip-scheduled lighten-5 px-2"> Scheduled </v-chip>
                                        <span v-text="item.enddate" class="ml-2"></span>
                                    </v-list-item-subtitle>
                                    <div class="list-action-button d-flex">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon small v-bind="attrs" v-on="on">
                                                    <v-icon> mdi-calendar-remove </v-icon>
                                                </v-btn>
                                            </template>
                                            <span> Remove Mission </span>
                                        </v-tooltip>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.mission" class="text-caption"></v-list-item-title>
                                    <v-list-item-subtitle class="subtitle-2 text--primary mt-1" v-text="item.startdate"> </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-chip class="chip-list chip-canceled lighten-5 px-2"> Canceled </v-chip>
                                        <span v-text="item.enddate" class="ml-2"></span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.mission" class="text-caption"></v-list-item-title>
                                    <v-list-item-subtitle class="subtitle-2 text--primary mt-1" v-text="item.startdate"> </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-chip class="chip-list chip-aborted px-2" text-color="white"> aborted </v-chip>
                                        <span v-text="item.enddate" class="ml-2"></span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.mission" class="text-caption"></v-list-item-title>
                                    <v-list-item-subtitle class="subtitle-2 text--primary mt-1" v-text="item.startdate"> </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-chip class="chip-list chip-failed px-2" text-color="white"> Failed </v-chip>
                                        <span v-text="item.enddate" class="ml-2"></span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item :key="item.title">
                                <template v-slot:default="{ active }">
                                    <template v-if="!active">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.mission" class="text-caption"></v-list-item-title>
                                            <v-list-item-subtitle class="subtitle-2 text--primary mt-1" v-text="item.startdate"> </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                <!-- <v-chip class="chip-list chip-scheduled lighten-5 px-2">
                          Scheduled
                        </v-chip> -->
                                                <v-chip class="chip-list chip-complete lighten-5 px-2"> Complete </v-chip>
                                                <span v-text="item.enddate" class="ml-2"></span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                    <template v-else>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.mission" class="text-caption"></v-list-item-title>
                                            <v-list-item-subtitle class="subtitle-2 text--primary mt-1" v-text="item.startdate"> </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                <!-- <v-chip class="chip-list chip-scheduled lighten-5 px-2">
                          Scheduled
                        </v-chip> -->
                                                <v-chip class="chip-list chip-complete lighten-5 px-2"> Complete </v-chip>
                                                <span v-text="item.enddate" class="ml-2"></span>
                                            </v-list-item-subtitle>
                                            <div class="list-scan-location pa-3 mt-3">
                                                <div class="text-caption">Scan locations</div>
                                                <div class="body-1 mt-2">
                                                    <span v-text="item.locations"></span>, <span class="text-orange">104-01-A-01</span>,
                                                    <span class="text-grey">104-01-A-01</span>
                                                </div>
                                                <div class="list-action-button d-flex">
                                                    <!--Dialog Preview Scan-->
                                                    <Dialog :width="1000">
                                                        <template v-slot:dialogLinkGroup>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn icon class="mr-2" color="info" small v-bind="attrs" v-on="on">
                                                                        <v-icon> mdi-data-matrix-scan </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span> Preview Scan</span>
                                                            </v-tooltip>
                                                        </template>
                                                        <template v-slot:dialogTitle> Preview Scan </template>
                                                        <template v-slot:dialogContent>
                                                            <v-card-text class="py-3">
                                                                <v-tabs vertical>
                                                                    <v-tab>
                                                                        <v-icon left small> mdi-quadcopter </v-icon>
                                                                        Base 1
                                                                    </v-tab>
                                                                    <v-tab>
                                                                        <v-icon left small> mdi-quadcopter </v-icon>
                                                                        Base 2
                                                                    </v-tab>
                                                                    <v-tab>
                                                                        <v-icon left small> mdi-quadcopter </v-icon>
                                                                        Base 3
                                                                    </v-tab>

                                                                    <v-tab-item>
                                                                        <v-card outlined>
                                                                            <v-card-text>
                                                                                <v-row justify="end">
                                                                                    <v-col cols="auto">
                                                                                        <span class="subheading font-weight-light mr-1">Max Cost</span>
                                                                                        <span class="text-h5 font-weight-bold" v-text="bpm"></span>
                                                                                    </v-col>
                                                                                    <v-col cols="6">
                                                                                        <v-slider v-model="bpm" :color="color" track-color="grey" hide-details always-dirty min="40" max="218">
                                                                                            <template v-slot:prepend>
                                                                                                <v-icon :color="color" @click="decrement"> mdi-minus </v-icon>
                                                                                            </template>
                                                                                            <template v-slot:append>
                                                                                                <v-icon :color="color" @click="increment"> mdi-plus </v-icon>
                                                                                            </template>
                                                                                        </v-slider>
                                                                                    </v-col>
                                                                                    <v-col cols="auto">
                                                                                        <v-btn :color="color" dark depressed small> Resend </v-btn>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-card-text>
                                                                            <v-divider></v-divider>
                                                                            <v-tabs v-model="tab">
                                                                                <v-tab v-for="item in tabitems" :key="item.tab">
                                                                                    {{ item.tab }}
                                                                                </v-tab>
                                                                            </v-tabs>
                                                                            <v-divider></v-divider>
                                                                            <v-tabs-items v-model="tab">
                                                                                <v-tab-item v-for="item in tabitems" :key="item.tab">
                                                                                    <v-card flat>
                                                                                        <v-card-title>{{ item.title }}</v-card-title>
                                                                                        <v-card-text>{{ item.content }}</v-card-text>
                                                                                    </v-card>
                                                                                </v-tab-item>
                                                                            </v-tabs-items>
                                                                        </v-card>
                                                                    </v-tab-item>
                                                                    <v-tab-item>
                                                                        <v-card outlined> </v-card>
                                                                    </v-tab-item>
                                                                    <v-tab-item>
                                                                        <v-card outlined> </v-card>
                                                                    </v-tab-item>
                                                                </v-tabs>
                                                            </v-card-text>
                                                        </template>
                                                        <template v-slot:dialogActionRight>
                                                            <v-btn text @click="dialog = false"> Close </v-btn>
                                                        </template>
                                                    </Dialog>
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon small v-bind="attrs" v-on="on">
                                                                <v-icon class="icon-image icon-toadhoc"> mdi-plus </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span> Ad Hoc Scan </span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                            <div class="list-scan-location pa-3 mt-1">
                                                <div class="text-caption">History</div>
                                                <v-timeline align-top dense>
                                                    <v-timeline-item color="success" small>
                                                        <div class="body-1">
                                                            <span class="pl-1">04/20/20 10:42</span>
                                                        </div>
                                                        <div class="body-1">
                                                            Regular scan started by <span class="text-batch">BATCH</span>
                                                            completed.
                                                        </div>
                                                    </v-timeline-item>
                                                    <v-timeline-item color="error" small>
                                                        <div class="body-1">
                                                            <span class="pl-1">04/20/20 10:42</span>
                                                        </div>
                                                        <div class="body-1">
                                                            Regular scan started by <span class="text-batch">BATCH</span> completed. Out of <a class="text-locations cursor-pointer">34 locations</a>,
                                                            <a class="text-scanned cursor-pointer">23 scanned</a>, <a class="text-discrepancies cursor-pointer">0 discrepancies</a> and
                                                            <a class="text-unidentified">4 unidentified</a> locations found.
                                                        </div>
                                                    </v-timeline-item>
                                                </v-timeline>
                                            </div>
                                            <div class="list-scan-location pa-3 mt-1">
                                                <div class="text-caption">Description</div>
                                                <div class="body-1 mt-1" v-text="item.description"></div>
                                            </div>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-list-item>
                            <v-divider></v-divider>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import SideTitle from '@/components/SideTitle.vue';
import ListFilters from '@/components/ListFilters.vue';
import FileDownload from '@/components/agGrid/FileDownload.vue';
import dateRangePicker from '@/components/dateRangePicker.vue';
import Dialog from '@/components/Dialog.vue';

export default {
    name: 'RegularScanPage',
    components: {
        SideTitle,
        ListFilters,
        FileDownload,
        dateRangePicker,
        Dialog,
    },
    data: () => ({
        selected: [2],
        items: [
            {
                mission: 'Regular',
                startdate: '09/29/2022 00:00',
                enddate: `09/29/2022 00:00`,
                description: 'Mission scan completed',
                locations:
                    '104-01-A-01, 104-01-A-02, 104-01-B-02, 104-01-C-01, 104-01-C-02, 104-02-A-01, 104-02-A-02, 104-02-B-01, 104-02-C-01, 104-02-C-02, 104-03-D-01, 104-03-D-02, 104-03-E-01, 104-03-E-02, 104-04-A-01, 104-04-A-02, 104-04-B-01, 104-04-B-02, 104-04-C-01, 104-04-C-02, 105-04-A-01, 105-04-A-02, 105-04-B-01, 105-04-B-02, 105-04-C-01, 105-04-C-02, 105-03-A-01, 105-03-A-02, 105-03-B-01, 105-03-B-02, 105-03-C-01, 105-03-C-02, 105-01-A-01, 105-01-A-02',
            },
        ],
        tab: null,
        tabitems: [
            { tab: 'Group One', title: 'Tab Title', content: 'Tab 1 Content' },
            { tab: 'Group Two', title: 'Tab Title', content: 'Tab 2 Content' },
            { tab: 'Group Three', title: 'Tab Title', content: 'Tab 3 Content' },
            { tab: 'Group Four', title: 'Tab Title', content: 'Tab 4 Content' },
            { tab: 'Group Five', title: 'Tab Title', content: 'Tab 5 Content' },
            { tab: 'Group Six', title: 'Tab Title', content: 'Tab 6 Content' },
            { tab: 'Group Seven', title: 'Tab Title', content: 'Tab 7 Content' },
            { tab: 'Group Eight', title: 'Tab Title', content: 'Tab 8 Content' },
            { tab: 'Group Nine', title: 'Tab Title', content: 'Tab 9 Content' },
            { tab: 'Group Ten', title: 'Tab Title', content: 'Tab 10 Content' },
        ],
        bpm: 40,
        interval: null,
    }),
    computed: {
        color() {
            if (this.bpm < 100) return 'indigo';
            if (this.bpm < 125) return 'teal';
            if (this.bpm < 140) return 'green';
            if (this.bpm < 175) return 'orange';
            return 'red';
        },
        animationDuration() {
            return `${60 / this.bpm}s`;
        },
    },

    methods: {
        decrement() {
            this.bpm--;
        },
        increment() {
            this.bpm++;
        },
    },
};
</script>

<style lang="scss">
.list-action-button {
    position: absolute;
    top: 5px;
    right: 5px;
}

.list-scan-location {
    background-color: #ffffff !important;
    position: relative;
    z-index: 1;
    color: #000000;
}

.list-scan-location .text-caption {
    font-weight: 500;
}
</style>
