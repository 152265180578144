<template>
    <!-- v-bind="$attrs" => 부모 Element에서 지정한 속성을 그대로 기술 -->
    <!-- v-on="$listeners" => 부모 Element에서 지정한 이벤트를 기술 -->
    <!-- v-if="visible" => 버튼의 권한에 따라 화면에 표시할지 여부 -->

    <div
        class="v-application"
        style="position: absolute; top: 0; left: 0; display: flex; width: 100%; height: 100%; z-index: 1000"
        v-if="visible"
    >
        <div style="position: absolute; width: 100%; height: 100%; background-color: black; opacity: 0.5"> </div>
        <div
            style="
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            "
        >
            <v-progress-circular
                v-bind="{ ...$props, ...$attrs }"
                v-on="$listeners"
                :size="70"
                :width="7"
                color="primary"
                indeterminate
            >
                <v-icon v-if="isShowClose" @click.stop="forceClose" style="z-index: 1000; right: -45px; top: -45px"
                    >mdi-close-circle-outline
                </v-icon>
            </v-progress-circular>
        </div>
    </div>
</template>

<script>
/**
 * BwSpinner Component
 * Buefy의  Loading 상속하여 구현함
 *
 * Ajax 호출시 config에 다음을 설정할 수 있다.
 *  isShowSpinner Spinner 표시 유무
 *  spinnerId 특정 Spinner 지정
 */

import axios from 'axios';
import { get, call } from 'vuex-pathify';
import _ from 'lodash';
import http from '@/api/http';
import i18n from '@/i18n/';

/** Spinner를 표시할지 판단하는 debounce 함수의 WAIT 값 */
const MIN_SHOW_MS = 300;

export default {
    name: 'BwSpinner',
    // mixin: [Loading], //Mixin을 하지 않아도 잘 됨
    inheritAttrs: false, // 부모 Component에서 지정한 Element 속성을 무시 - vue 파일의 다음 구분 참조(v-bind="$attrs", v-on="$listeners"

    props: {
        visible: {
            type: Boolean,
            default: false,
        },

        minShowMs: {
            // Spinner가 보여지는 최소 시간(ms)
            type: Number,
            default: MIN_SHOW_MS,
        },
        isShowCloseMessage: {
            // Spinner가 강제로 닫힐때 메시지 표시 유무
            type: Boolean,
            default: true,
        },
        closeMessage: {
            // Spinner의 강제 닫힐때 표시할 메시지
            type: String,
            default: i18n.t('common.message.canceledReqeust'), //'응답 대기중인 모든 네트워크 요청을 취소하였습니다.'
        },
        isShowClose: {
            // 강제 닫기 버튼 표시 유무
            type: Boolean,
            default: true,
        },
        isGlobal: {
            // Global Spinner - Global Spinner는 1개만 존재해야 한다.
            type: Boolean,
            default: false,
        },
    }, // end props

    beforeCreate() {}, // end beforeCreate
    created() {},

    beforeMounte() {}, //end beforeMounte
    mounted() {
        this.enableInterceptor();
    }, // end mounted

    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    beforeDestroy() {
        // 인터셉터 제거
        http.interceptors.request.eject(this.requestInterceptorId);
        http.interceptors.response.eject(this.responseInterceptorId);
    }, // end beforeDestroy

    destroyed() {}, // end destroyed

    computed: {},
    watch: {},

    methods: {
        /**
         * Spinner 자신의 ref ID를 찾는다.
         * @returns 자신의 ref ID
         */
        getSelfRefId() {
            let selfKey;
            for (let key in this.$parent.$refs) {
                if (this.$parent.$refs[key] === this) {
                    selfKey = key;
                    break;
                }
            }

            return selfKey;
        },

        /**
         * Ajax 호출시 Interceptor 할지 판단
         * @returns true : Interceptor 해야 함
         */
        isMyAxiosInterceptor(axiosRequestConfig) {
            let selfRefId = this.getSelfRefId();

            // Ajax 호출시 spinnerId를 지정하면 Global Spinner는 표시하지 않는다.
            if (this.isGlobal && axiosRequestConfig?.spinnerId && axiosRequestConfig?.spinnerId !== selfRefId)
                return false;

            // Ajax호출시 spinnerId를 지정하지 않으면 Global Spinner 보여진다.
            if (!this.isGlobal && (!selfRefId || axiosRequestConfig?.spinnerId !== selfRefId)) return false;

            return true;
        },
        /**
         * axios의 Request, Response에 대한 Interceptor
         */
        enableInterceptor() {
            // Request에 대한 Interceptor
            this.requestInterceptorId = http.interceptors.request.use(
                (config) => {
                    // 요청 성공 직전에 호출된다.
                    if (!this.isMyAxiosInterceptor(config)) return config;

                    let isShowSpinner = config.isShowSpinner === undefined ? true : config.isShowSpinner;
                    if (isShowSpinner === true) {
                        // Ajax 호출시 Loading Bar 표시함 옵션이면
                        this.increaseCount(); // Ajax 호출시 isShowSpinner를 false로 하면 Loading Bar가 표시되지 않음
                    }
                    config.cancelToken = this.ajaxCancelTokenSource.token; // Ajax 요청을 취소할 수 있게 Token 설정
                    return config;
                },
                (error) => {
                    // 요청 에러 직전에 호출된다.
                    return Promise.reject(error);
                },
            );

            // Response에 대한 Interceptor
            this.responseInterceptorId = http.interceptors.response.use(
                (response) => {
                    // 응답 성공 직전에 호출된다.
                    if (!this.isMyAxiosInterceptor(response.config)) return response;

                    if (response.config?.isShowSpinner !== false) {
                        this.decreaseCount(); // Ajax 호출시 isShowSpinner를 false로 하면 Loading Bar가 표시되지 않음
                    }

                    return response;
                },
                (error) => {
                    // 응답 에러 직전에 호출된다.
                    if (!this.isMyAxiosInterceptor(error.config)) return Promise.reject(error);

                    if (error.config?.isShowSpinner !== false) {
                        this.decreaseCount(); // Ajax 호출시 isShowSpinner를 false로 하면 Loading Bar가 표시되지 않음
                    }

                    switch (error.response.status) {
                        case 401:
                            console.log('ooooooooooooo');
                            return new Promise(() => {}); // 이행되지 않는 Promise를 반환하여 Promise Chaining 끊어주기
                        default:
                            return Promise.reject(error);
                    }
                },
            );
        }, // end enableInterceptor

        /**
         * 요청 카운트 1 증가
         */
        increaseCount() {
            this.count++;
            this.show();
        },

        /**
         * 요청 카운트 1 감소
         */
        decreaseCount() {
            if (this.count <= 0) return;

            this.count--;
            if (this.count <= 0) {
                this.hide();
            }
        },

        /** Spinner를 보이게 한다. */
        show() {
            // this.increaseCount(true);
            if (!this.visible) {
                this.startRequestTime = new Date().getTime();
            }

            this.setVisible(true);
            // this.visible_ = true;
        },

        /** Spinner를 숨긴다
         */
        hide() {
            // this.decreaseCount(true);
            if (!this.visible) return;

            let diff = new Date().getTime() - this.startRequestTime;

            if (diff > this.minShowMs) {
                // 500ms 동안 보여졌었으면 곧바로 hide
                this.count = 0;
                this.setVisible(false);
                // this.visible_ = false;
            } else {
                // 최소 500ms 지연
                let self = this;
                _.debounce(function () {
                    self.count = 0;
                    // self.visible_ = false;
                    self.setVisible(false);
                }, self.minShowMs)();
            }
        },

        toogleVisible() {
            this.setVisible(!this.visible);
        },

        setVisible(visible) {
            this.$emit('update:visible', visible);
        },

        /**
         * 강제 닫기 버튼 클릭 이벤트 처리
         */
        forceClose() {
            this.ajaxCancelTokenSource.cancel('canceled network reqeust');
            this.ajaxCancelTokenSource = axios.CancelToken.source(); // 닫기 버튼 클릭시 Ajax 요청을 취소하기 위한 Token Source

            if (this.isShowCloseMessage && this.count > 0) {
                this.$toast.warning(
                    this.closeMessage, // '응답 대기중인 네트워크 요청 정보를 모두 취소하였습니다.'
                );
            }

            this.hide();
        },
    }, // end methods

    data() {
        return {
            count: 0, // ajax요청 Count, ajax요청시 1증가, 완료시 1 감소
            startRequestTime: new Date().getTime(), // Request를 여러번 실행 경우 최초 Reqeust 시간
            ajaxCancelTokenSource: axios.CancelToken.source(), // 닫기 버튼 클릭시 Ajax 요청을 취소하기 위한 Token Source
            requestInterceptorId: -1, // 요청 인터셉터 ID, 제거용
            responseInterceptorId: -1, // 응답 인터셉터 ID, 제거용
        };
    }, // end data
};
</script>
