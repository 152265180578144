import Vue from 'vue';
import App from '@/App.vue';
import { router } from '@/router/index.js';
import vuetify from '@/plugins/vuetify';

import Components from '@/components/';
import store from '@/store';
import { sync } from 'vuex-router-sync';
import VueCookies from 'vue-cookies';
// import 'ag-grid-enterprise';
import i18n from '@/i18n/';
import Confirm from '@/common/confirm.js';
import Common from '@/common/common.js';
import Dialog from '@/common/dialog.js';
import Validator from '@/common/validator.js';

// import GlobalComponents from './components/GlobalComponents';

Vue.config.productionTip = false;
Vue.use(Components);
// Vue.use(GlobalComponents);
Vue.use(VueCookies);
Vue.use(Confirm);
Vue.prototype.$common = Common;
Vue.prototype.$dialog = Dialog;
Vue.prototype.$validator = Validator;

sync(store, router);

let vue = new Vue({
    router,
    vuetify,
    store,
    i18n,
    beforeCreate() {
        // this.$store.dispatch("loginStore/getMemberInfo",'beforeCreate');
    },
    render: (h) => h(App),
}).$mount('#app');

export default vue;
