<template>
    <!-- v-bind="$attrs" => 부모 Element에서 지정한 속성을 그대로 기술 -->
    <!-- v-on="$listeners" => 부모 Element에서 지정한 이벤트를 기술 -->
    <div>
        <div class="bwgrid-header" style="display: flex; justify-content: space-between" v-if="bwgridHeaderVisible">
            <div class="bwgrid-header-left">
                <span class="bwgrid-header-img"><img :src="bwgridTitleIcon" /></span>
                <span class="bwgrid-title">
                    {{ bwgridTitle }}
                </span>
            </div>
            <div class="bwgrid-buttons">
                <input
                    type="file"
                    name="importExcelFile"
                    style="display: none"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    @change="onChangeImportExcelFile"
                />
                <bw-btn
                    class="bwgrid-importexcel-btn"
                    @click="onClickImportExcel"
                    btn-authority="C"
                    v-if="bwgridImportBtnVisible"
                >
                    Import
                </bw-btn>
                <bw-btn class="bwgrid-exportexcel-btn" @click="onClickExportExcel" v-if="bwgridExportBtnVisible">
                    Export
                </bw-btn>
                <bw-btn class="bwgrid-exportpdf-btn" @click="onClickExportPdf" v-if="bwgridExportPdfBtnVisible">
                    PDF
                </bw-btn>
                <bw-btn class="bwgrid-refresh-btn" @click="onClickRefreshData" v-if="bwgridRefreshFn"> Refresh </bw-btn>
                <bw-btn
                    class="bwgrid-restore-btn"
                    @click="onClickRestoreData"
                    btn-authority="U"
                    v-if="bwgridRestoreBtnVisible"
                >
                    Restore
                </bw-btn>
                <bw-btn class="bwgrid-hidecolumn-btn" @click="onClickHideColumn" v-if="bwgridHideColumns">
                    Hide Column
                </bw-btn>
                <bw-btn
                    class="bwgrid-addrow-btn"
                    @click="onClickAddRow"
                    btn-authority="C"
                    v-if="bwgridAddRowBtnVisible"
                >
                    Add Row
                </bw-btn>
                <bw-btn
                    class="bwgrid-removerow-btn"
                    @click="onClickRemoveRow"
                    btn-authority="D"
                    v-if="bwgridRemoveRowBtnVisible"
                >
                    Remove Row
                </bw-btn>
                <bw-btn class="bwgrid-print-btn" @click="onClickPrint" btn-authority="R" v-if="bwgridPrintBtnVisible">
                    Print
                </bw-btn>
            </div>
        </div>
        <ag-grid-vue
            :style="{ width: '100%', height: bwgridHeaderVisible ? 'calc(100% - 36px)' : '100%' }"
            class="ag-theme-balham"
            @grid-ready="onGridReady"
            @selection-changed="onSelectionChanged"
            @row-selected="onRowSelected"
            @first-data-rendered="onFirstDataRendered"
            @row-data-updated="onRowDataUpdated"
            @cell-value-changed="onCellValueChanged"
            v-bind="{ ...$props, ...$attrs }"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-community';
import _ from 'lodash';
import moment from 'moment';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';

import BwDateFilterComponent from './BwDateFilterComponent';

/**
 * 이 주석은 다른 파일에 주석을 달기 위한 가이드 역할을 합니다.
 * JSDoc으로 작성한 주석은 향후 HTML로 추출될 수 있기 때문에 최초작성자/최초작성일 등은 기술하지 않습니다.
 * 이러한 정보는 git의 History(log) 기능을 이용하여 조회할 수 있습니다.
 *
 * .vue 파일과 .js 파일은 반드시 분리하며 주석은 .js에만 기술하면 됩니다.
 *
 * Method 상단에는 @param과 @returns만 기술합니다.
 * Member는 옆에 단일라인 주석(//)을 사용하여 기술합니다.
 *
 * 아래는 실제 BwGrid에 대한 설명입니다.
 * ====================================
 * BwGrid Component
 *
 * Bw Grid Event
 *   bwgrid-before-add-row : Row 추가의 경우 Default값을 설정시 사용할 수 있습니다.
 *   bwgrid-before-added-row : Row 추가 완료 이벤트
 *   bwgrid-deleted-rows : Rows 삭제 완료 이벤트
 *   참고1 : Cell을 수정한 경우 부모 Component에서 "cell-value-changed"를 받아서 처리하면 됩니다.
 *   참고1 : Row를 수정한 경우 부모 Component에서 "row-value-changed"를 받아서 처리하면 됩니다.
 */
export default {
    name: 'BwGrid',
    components: {
        'ag-grid-vue': AgGridVue,
        BwDateFilterComponent,
    },
    // mixin: [Grid], //Mixin을 하지 않아도 잘 됨
    inheritAttrs: false, // 부모 Component에서 지정한 Element 속성을 무시 - vue 파일의 다음 구분 참조(v-bind="$attrs", v-on="$listeners")

    props: {
        /**
         * Pagenation 옵션
         * AgGrid의 pagination 옵션을 true로 유지해야 한다.
         * AgGrid의 pagination 옵션은 Summary와 Pagination 부분을 모두 보게 하는 옵션이므로 bwgridPaginationVisible과 bwgridSummaryVisible로 두 기능을 분리하였다.
         */
        bwgridPaginationVisible: {
            type: Boolean,
            default: false,
            required: false,
        },

        /**
         * Summary 보기 옵션
         */
        bwgridSummaryVisible: {
            type: Boolean,
            default: false,
            required: false,
        },

        /**
         * CUD Count 보기 옵션
         */
        bwgridCudCountVisible: {
            type: Boolean,
            default: false,
            required: false,
        },

        /**
         * 선택된 수 보기 옵션
         */
        bwgridSelectedCountVisible: {
            type: Boolean,
            default: false,
            required: false,
        },

        /**
         * Total Count 보기 옵션
         */
        bwgridTotalCountVisible: {
            type: Boolean,
            default: false,
            required: false,
        },

        /**
         * 헤더 영역 보기 옵션
         */
        bwgridHeaderVisible: {
            type: Boolean,
            default: false,
            required: false,
        },

        /**
         * 헤더의 타이틀
         */
        bwgridTitle: {
            type: String,
            required: false,
        },

        /**
         * 헤더의 타이틀 좌측 아이콘
         */
        bwgridTitleIcon: {
            type: String,
            required: false,
            default:
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAkJJREFUSEvFlTtoFFEUhv9/EzcEg6iI+Ehik5nFhKhFLHZMkVjlISp5sFYKYqtEYhVLCxElojZ2IhaGNFpFtFlFMI2KszHqTJYkO0ZEBRUE0czuHJnNruzsczYSvNU8zvm+e2fuOZdY48E15qOsoGVqrm53Y/BELdhDQRuBnSAEIh8c4azD5IMl++vtFx0ddqmJlhQMzyxE7FRgnMSOcqskaQXJkYn2pnvF4ooKBmPWuCNy1u/nI+nUEpcm25vH8nMKBAOxxFURjPiFZ+M+2yl8WbbHTC10MTfXIxh6bR1LpeTu6uDJdBqdwEGjsyWaZfwVtEZnG5RNDQsBYks1gpWZr8DTQ6CbB9R9BYJ+ffFcELz8T/BMcgDseacpD9MrygIPvVqMriO7/AoKZp6TSOKWEVZPegRH9MSnALDVj6AcPJP/xtTUNo9gIJb4JYK6SgIfcBfxzdTUzV6Bbv0USH05gU+4i/hhauoGj+CoblmENJUSVAF3EW9NTW31CA7r1lQNpLeYoEq4u1fvmFrouEfQ/zJxOliD6/mC6uEAyYgRViY9gsZn7+v3r3fmAWzLSlYDB2A437k33qf89gjcm+EZK5J0ZMK9XiXcgSNdZmfoaUElZx8M6daVj3Zy1FP+lfZu5r0QZ+bC6o3c8KLtWpk2z1NwIbfSKzhSBEYNTb2WH1fywFGnjV4KbwrQXAEed8BTcU15Uiyu4pHJjTIIQR/JPYDsSvdLYB7kczp4tH156f7j7u6cdurV/N9D3+e/LRv2B2G59hmud3L2AAAAAElFTkSuQmCC',
        },

        /**
         * Import Button 표시 유무
         */
        bwgridImportBtnVisible: {
            type: Boolean,
            default: false,
            required: false,
        },

        /**
         * Export Excel Button 표시 유무
         */
        bwgridExportBtnVisible: {
            type: Boolean,
            default: false,
            required: false,
        },

        /**
         * Export PDF Button 표시 유무
         */
        bwgridExportPdfBtnVisible: {
            type: Boolean,
            default: false,
            required: false,
        },

        /**
         * Refresh Button 버튼 클릭시 사용할 Function
         * 이 Function은 async로 작성되어야 한다.
         */
        bwgridRefreshFn: {
            type: Function,
            required: false,
        },

        /**
         * Restore Button 표시 유무
         */
        bwgridRestoreBtnVisible: {
            type: Boolean,
            default: false,
            required: false,
        },

        /**
         * Hide Column에 사용할 컬럼들 지정
         */
        bwgridHideColumns: {
            type: Array,
            required: false,
        },

        /**
         * Add Row Button 표시 유무
         */
        bwgridAddRowBtnVisible: {
            type: Boolean,
            default: false,
            required: false,
        },

        /**
         * Remove Row Button 표시 유무
         */
        bwgridRemoveRowBtnVisible: {
            type: Boolean,
            default: false,
            required: false,
        },

        /**
         * Print Button 표시 유무
         */
        bwgridPrintBtnVisible: {
            type: Boolean,
            default: false,
            required: false,
        },
    }, // end props

    beforeCreate() {}, // end beforeCreate
    created() {}, // end created
    beforeMount() {
        // this.gridOptions.frameworkComponents.bwDateFilterComponent = BwDateFilterComponent;
    },
    mounted() {}, // end mounted
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    beforeDestroy() {}, // end beforeDestroy
    destroyed() {}, // end destroyed
    computed: {}, // end computed

    watch: {}, // end watch

    methods: {
        /**
         * BwGrid에서 추가한 화면 부분 초기화
         */
        initBwgridDisplayArea() {
            this.removedData = [];

            if (this.bwgridCudCountVisible === true) {
                this.$el.querySelector('.bwgrid-ccount').innerText = '0';
                this.$el.querySelector('.bwgrid-ucount').innerText = '0';
                this.$el.querySelector('.bwgrid-dcount').innerText = '0';
            }

            // if (this.bwgridSelectedCountVisible) {
            //     this.$el.querySelector('.bwgrid-selected-rows-view-btn').classList.remove('active');
            // }

            if (this.bwgridCudCountVisible) {
                this.$el.querySelector('.bwgrid-ccount').classList.remove('active');
                this.$el.querySelector('.bwgrid-ucount').classList.remove('active');
            }

            if (this.bwgridSelectedCountVisible) {
                this.$el.querySelector('.bwgrid-selectedcount').innerText = '0';
            }

            if (this.bwgridTotalCountVisible) {
                this.$el.querySelector('.bwgrid-totalcount').innerText = this.gridApi.getDisplayedRowCount();
            }
        },

        /**
         * 그리드 초기화시
         */
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;

            let columnDefs = this.$attrs['column-defs'];
            columnDefs.push({
                field: 'bwgridCud',
                hide: true,
                bwgridIgnoreExcelExport: true,
            });
            columnDefs.push({
                field: 'bwgridSelected',
                hide: true,
                bwgridIgnoreExcelExport: true,
            });

            let agPagingPanel = this.$el.querySelector('.ag-paging-panel');

            if (!this.bwgridSummaryVisible) {
                this.$el.querySelector('.ag-paging-row-summary-panel').classList.add('ag-hidden'); // 내용은 보이지 않게
            }

            if (!this.bwgridPaginationVisible) {
                this.$el.querySelector('.ag-paging-page-summary-panel').classList.add('ag-hidden'); // 내용은 보이지 않게
            }

            if (this.bwgridCudCountVisible === true) {
                let outerTag = document.createElement('div');
                outerTag.classList.add('bwgrid-cudcount');
                agPagingPanel.prepend(outerTag);

                let cTag = document.createElement('span');
                cTag.classList.add('bwgrid-ccount');
                cTag.title = 'inserted';
                cTag.innerText = '0';

                cTag.addEventListener('click', (e) => {
                    if (!e.target.classList.contains('active')) {
                        if (this.bwgridSelectedCountVisible) {
                            this.$el.querySelector('.bwgrid-selectedcount').classList.remove('active');
                        }
                        this.$el.querySelector('.bwgrid-ucount').classList.remove('active');
                        e.target.classList.add('active');

                        this.gridApi.setFilterModel({
                            // 필터 설정
                            bwgridCud: {
                                type: 'equals',
                                filter: 'C',
                                // condition1: {
                                //     type: 'equals',
                                //     filter: 'C',
                                // },
                                // condition2: {
                                //     type: 'equals',
                                //     filter: 'U',
                                // },
                                // operator: 'OR',
                            },
                        });
                    } else {
                        e.target.classList.remove('active');
                        this.gridApi.setFilterModel(null); // 필터 해제
                    }
                });

                let uTag = document.createElement('span');
                uTag.classList.add('bwgrid-ucount');
                uTag.title = 'modified';
                uTag.innerText = '0';

                uTag.addEventListener('click', (e) => {
                    if (!e.target.classList.contains('active')) {
                        if (this.bwgridSelectedCountVisible) {
                            this.$el.querySelector('.bwgrid-selectedcount').classList.remove('active');
                        }
                        this.$el.querySelector('.bwgrid-ccount').classList.remove('active');
                        e.target.classList.add('active');

                        this.gridApi.setFilterModel({
                            // 필터 설정
                            bwgridCud: {
                                type: 'equals',
                                filter: 'U',
                            },
                        });
                    } else {
                        e.target.classList.remove('active');
                        this.gridApi.setFilterModel(null); // 필터 해제
                    }
                });

                let dTag = document.createElement('span');
                dTag.classList.add('bwgrid-dcount');
                dTag.title = 'removed';
                dTag.innerText = '0';

                outerTag.append(cTag);
                outerTag.append(' | ');
                outerTag.append(uTag);
                outerTag.append(' | ');
                outerTag.append(dTag);

                // let btnSelectedView = document.createElement('button');
                // btnSelectedView.classList.add('bwgrid-selected-rows-view-btn');
                // btnSelectedView.title = 'toggle selected rows';
                // btnSelectedView.addEventListener('click', (e) => {
                //     if (!e.target.classList.contains('active')) {
                //         this.$el.querySelector('.bwgrid-updated-rows-view-btn').classList.remove('active');
                //         e.target.classList.add('active');
                //         this.gridApi.setFilterModel({
                //             // 필터 설정
                //             bwgridSelected: {
                //                 filterType: 'set',
                //                 values: ['true'],
                //             },
                //         });
                //     } else {
                //         e.target.classList.remove('active');
                //         this.gridApi.setFilterModel(null); // 필터 해제
                //     }
                // });

                // let btnUpdatedView = document.createElement('button');
                // btnUpdatedView.classList.add('bwgrid-updated-rows-view-btn');
                // btnUpdatedView.title = 'toggle modified rows';
                // btnUpdatedView.addEventListener('click', (e) => {
                //     if (!e.target.classList.contains('active')) {
                //         if (this.bwgridSelectedCountVisible) {
                //             this.$el.querySelector('.bwgrid-selectedcount').classList.remove('active');
                //         }
                //         e.target.classList.add('active');

                //         console.log(this.gridApi.getFilterModel());
                //         this.gridApi.setFilterModel({
                //             // 필터 설정
                //             bwgridCud: {
                //                 condition1: {
                //                     type: 'equals',
                //                     filter: 'C',
                //                 },
                //                 condition2: {
                //                     type: 'equals',
                //                     filter: 'U',
                //                 },
                //                 operator: 'OR',
                //             },
                //         });
                //     } else {
                //         e.target.classList.remove('active');
                //         this.gridApi.setFilterModel(null); // 필터 해제
                //     }
                // });

                // outerTag.append(btnSelectedView);
                // outerTag.append(btnUpdatedView);
            }

            if (this.bwgridSelectedCountVisible || this.bwgridTotalCountVisible) {
                let outerTag = document.createElement('span');

                agPagingPanel.classList.remove('ag-hidden');
                agPagingPanel.append(outerTag);

                if (this.bwgridSelectedCountVisible) {
                    let selectedTag = document.createElement('span');
                    selectedTag.classList.add('bwgrid-selectedcount');
                    selectedTag.title = 'selected';
                    selectedTag.innerText = '0';
                    outerTag.append(selectedTag);

                    selectedTag.addEventListener('click', (e) => {
                        if (!e.target.classList.contains('active')) {
                            if (this.bwgridCudCountVisible) {
                                this.$el.querySelector('.bwgrid-ccount').classList.remove('active');
                                this.$el.querySelector('.bwgrid-ucount').classList.remove('active');
                            }
                            e.target.classList.add('active');
                            this.gridApi.setFilterModel({
                                // 필터 설정
                                bwgridSelected: {
                                    type: 'equals',
                                    filter: true,
                                },
                            });
                        } else {
                            e.target.classList.remove('active');
                            this.gridApi.setFilterModel(null); // 필터 해제
                        }
                    });
                }

                if (this.bwgridSelectedCountVisible && this.bwgridTotalCountVisible) {
                    outerTag.append(' / ');
                }

                if (this.bwgridTotalCountVisible) {
                    let totalTag = document.createElement('span');
                    totalTag.innerText = '0';
                    totalTag.classList.add('bwgrid-totalcount');
                    totalTag.title = 'total';
                    outerTag.append(totalTag);
                }
            }
        },

        /**
         * 최초 Data Rendering시
         * @param {*} param
         */
        onFirstDataRendered({ api, columnApi }) {
            this.oriData = _.cloneDeep(this.$attrs['row-data']);
            if (this.bwgridTotalCountVisible) {
                this.$el.querySelector('.bwgrid-totalcount').innerText = api.getDisplayedRowCount();
            }

            this.removedData = [];

            this.initBwgridDisplayArea();

            if (!_.isEmpty(this.bwgridHideColumns)) {
                // 컬럼 보이기/숨기기가 지정되어 있으면
                if (!this.$el.querySelector('.bwgrid-hidecolumn-btn').classList.contains('active')) {
                    this.$el.querySelector('.bwgrid-hidecolumn-btn').click(); // 곧바로 적용
                }
            }
        },

        /**
         * 데이터 수정시
         * @param {*} param
         */
        onRowDataUpdated({ api, columnApi }) {
            if (this.bwgridTotalCountVisible) {
                this.$el.querySelector('.bwgrid-totalcount').innerText = api.getDisplayedRowCount();
            }
        },

        /**
         * 행 선택 변경시
         * @param {*} param0
         */
        onSelectionChanged({ api, columnApi }) {
            if (this.bwgridSelectedCountVisible) {
                const selectedRows = api.getSelectedRows();
                this.$el.querySelector('.bwgrid-selectedcount').innerText = selectedRows.length;
            }
        },

        /**
         * 행이 선택되거나 해제시
         */
        onRowSelected({ node, data: rowData }) {
            rowData.bwgridSelected = `${node.selected}`;
        },

        /**
         * 셀의 값 변경시
         */
        onCellValueChanged({ data: rowData }) {
            if (rowData.bwgridCud !== 'C') {
                rowData.bwgridCud = 'U';
                this.$el.querySelector('.bwgrid-ucount').innerText = this.getUpdatedRowCount();
            }
        },

        /**
         * Import 버튼 클릭시
         */
        onClickImportExcel() {
            this.$el.querySelector('[name=importExcelFile]').value = '';
            this.$el.querySelector('[name=importExcelFile]').click();
        },

        /**
         * Import할 파일 선택창에서 파일 선택시
         * @param {*} e
         */
        async onChangeImportExcelFile(e) {
            let blob = await e.target.files[0].arrayBuffer();
            let workbook = XLSX.read(blob, { type: 'binary' });

            let firstSheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[firstSheetName];

            const excelCols = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M',
                'N',
                'O',
                'P',
                'Q',
                'R',
                'S',
                'T',
                'U',
                'V',
                'W',
                'X',
                'Y',
                'Z',
            ];
            const defColObjs = this.$attrs['column-defs'];

            let index = 0;

            function getColumns(defColObjs) {
                let columns = {};
                for (const defColObj of defColObjs) {
                    if (defColObj.children) {
                        let cols = getColumns(defColObj.children);
                        columns = Object.assign(columns, cols);
                    } else {
                        if (defColObj.bwgridIgnoreExcelExport === true) {
                        } else {
                            let excelCol = excelCols[index % 26];
                            const index0 = Math.floor(index / 26);

                            if (index0 > 0) {
                                excelCol = excelCols[index0 - 1] + excelCol; // AA, AB 등
                            }

                            columns[excelCol] = defColObj;
                            index++;
                        }
                    }
                }

                return columns;
            }

            let columns = getColumns(defColObjs);

            // for (const defColObj of defColObjs) {
            //     if (defColObj.children) continue;
            //     if (defColObj.bwgridIgnoreExcelExport) continue;

            //     let excelCol = excelCols[index % 26];
            //     const index0 = Math.floor(index / 26);

            //     if (index0 > 0) {
            //         excelCol = excelCols[index0 - 1] + excelCol; // AA, AB 등
            //     }

            //     columns[excelCol] = defColObj;
            //     index++;
            // }

            let rowData = [];

            // start at the 2nd row - the first row are the headers
            let rowIndex = 2;

            // iterate over the worksheet pulling out the columns we're expecting
            while (worksheet['A' + rowIndex]) {
                let row = {};
                Object.keys(columns).forEach(function (column) {
                    let defColObj = columns[column];

                    // console.log(column + rowIndex, 'x' + worksheet[column + rowIndex] + 'x');
                    let value = worksheet[column + rowIndex]?.w;

                    if (defColObj.bwgridExcelExportDateFormat) {
                        value = moment(value, defColObj.bwgridExcelExportDateFormat).valueOf('x');
                    }

                    row[defColObj.field] = value;
                });

                rowData.push(row);

                rowIndex++;
            }

            // finally, set the imported rowData into the grid
            this.gridApi.setRowData(rowData);

            this.initBwgridDisplayArea();
        },

        /**
         * Export Excel 버튼 클릭시
         */
        onClickExportExcel() {
            // this.gridApi.exportDataAsExcel({
            //     fileName: this.bwgridTitle || 'export',
            //     sheetName: this.bwgridTitle || 'sheet1',
            // });

            const { rowsToDisplay } = this.gridApi.getModel();

            let data = rowsToDisplay.map((rowNode) => rowNode.data); // Grid의 Filtering이 적용된 값

            // Export할 Field들을 구한다(순서 있음)
            function getOrderdFields(defColObjs) {
                let orderedFields = [];
                for (const defColObj of defColObjs) {
                    if (defColObj.children) {
                        let fields = getOrderdFields(defColObj.children);
                        orderedFields = orderedFields.concat(fields);
                    } else {
                        if (defColObj.bwgridIgnoreExcelExport === true) {
                        } else {
                            orderedFields.push(defColObj.field);
                        }
                    }
                }

                return orderedFields;
            }

            // Export할 Field만 취함

            const defColObjects = this.$attrs['column-defs'];
            let orderedFields = getOrderdFields(defColObjects); // ['age', 'athlete', ...]

            // Export하지 않아야 할 Field는 제거
            data = data.map((row) => _.pick(row, orderedFields));

            // Date Format 적용
            for (const defColObj of defColObjects) {
                if (!defColObj.bwgridExcelExportDateFormat) continue;

                const fieldName = defColObj.field;
                // 원래의 row[fieldName]: 13자리 숫자, bwgridExcelExportDateFormat: 'MM/DD/YYYY'
                data = data.map((row) => {
                    row[fieldName] = moment(row[fieldName]).format(defColObj.bwgridExcelExportDateFormat);
                    return row;
                });
            }

            const ws = XLSX.utils.json_to_sheet(data, { header: orderedFields });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Data');

            let filename = (this.bwgridTitle || 'export') + '.xlsx';
            XLSX.writeFileXLSX(wb, filename);
        },

        /**
         * Export PDF 버튼 클릭시
         */
        onClickExportPdf() {
            let gSpinner = window.app.__vue__.$root.$children[0].$refs.gSpinner; // App.vue에 있는 Global Spinner

            gSpinner.show();

            setTimeout(() => {
                this.setPrinterFriendly();
                let eGridDiv = this.$el.getElementsByClassName('ag-root-wrapper')[0];

                html2pdf()
                    .set({
                        margin: 1,
                        filename: (this.bwgridTitle || 'export') + '.pdf',
                        image: { type: 'jpeg', quality: 0.98 },
                        html2canvas: { scale: 2 },
                        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                    })
                    .from(eGridDiv)
                    .save()
                    .then(() => {
                        this.setNormal();
                        gSpinner.hide();
                    })
                    .catch((error) => {
                        this.setNormal();
                        console.error(error);

                        gSpinner.hide();
                        this.$toast.info(error);
                    });
            }, 400); // Spinner가 잘 나오게 하기 위해 400ms delay를 둔다.
        },

        /**
         * Refresh 버튼 클릭시
         */
        async onClickRefreshData() {
            await this.bwgridRefreshFn();
            this.initBwgridDisplayArea();
        },

        /**
         * Restore 버튼 클릭시
         */
        onClickRestoreData() {
            this.gridApi.setRowData(_.cloneDeep(this.oriData));
            this.initBwgridDisplayArea();
        },

        /**
         * Hide Column 버튼 클릭시
         * @param {*} e
         */
        onClickHideColumn(e) {
            let element = e.target.__vue__ ? e.target : e.target.parentElement;
            if (!element.classList.contains('active')) {
                element.classList.add('active');

                this.gridColumnApi.setColumnsVisible(this.bwgridHideColumns, false);
            } else {
                element.classList.remove('active');

                this.gridColumnApi.setColumnsVisible(this.bwgridHideColumns, true);
            }
        },

        /**
         * Add Row 버튼 클릭시
         */
        onClickAddRow() {
            let newRow = {};
            this.$emit('bwgrid-before-add-row', newRow);

            newRow.bwgridCud = 'C';
            this.gridApi.applyTransaction({ add: [newRow], addIndex: 0 });

            if (this.bwgridCudCountVisible === true) {
                this.$el.querySelector('.bwgrid-ccount').innerText = this.getAddedRowCount();
            }
        },

        /**
         * Remove Row 버튼 클릭시
         */
        onClickRemoveRow() {
            let selectedRows = this.gridApi.getSelectedRows();
            if (_.isEmpty(selectedRows)) return;

            this.gridApi.applyTransaction({ remove: selectedRows });

            this.removedData = this.removedData.concat(selectedRows);

            selectedRows.forEach(function (selectedRow, index) {
                selectedRow.bwgridCud = 'D';
            });

            if (this.bwgridCudCountVisible === true) {
                this.$el.querySelector('.bwgrid-ccount').innerText = this.getAddedRowCount();
                this.$el.querySelector('.bwgrid-ucount').innerText = this.getUpdatedRowCount();
                this.$el.querySelector('.bwgrid-dcount').innerText = this.getDeletedRowCount();
            }

            this.$emit('bwgrid-deleted-rows', selectedRows);
        },

        /**
         * Print 버튼 클릭시
         * Print 기능을 사용하려면 Pagination을 사용하면 안됩니다. - 느려지기 때문
         *
         */
        async onClickPrint() {
            this.setPrinterFriendly();

            let mywindow = window.open('', 'mywindow', ``);
            mywindow.document.head.innerHTML = document.head.innerHTML;
            const eGridDiv = this.$el.getElementsByClassName('ag-root-wrapper')[0];

            mywindow.document.body.innerHTML = eGridDiv.outerHTML;

            mywindow.print();

            this.setNormal();
            mywindow.close();
        },

        /**
         * Print 가능하게 Grid를 변경
         */
        setPrinterFriendly() {
            const eGridDiv = this.$el.getElementsByClassName('ag-root-wrapper')[0];
            this.oriWidth = eGridDiv.style.width;
            this.oriHeight = eGridDiv.style.height;
            eGridDiv.style.width = '';
            eGridDiv.style.height = '500%';
            this.gridApi.setDomLayout('print');
        },

        /**
         * 원래대로 Grid를 변경
         */
        setNormal() {
            const eGridDiv = this.$el.getElementsByClassName('ag-root-wrapper')[0];
            eGridDiv.style.width = this.oriWidth;
            eGridDiv.style.height = this.oriHeight;
            this.gridApi.setDomLayout();
        },
        /**
         * 추가된 행 수 구하기
         */
        getAddedRowCount() {
            let { rowsToDisplay } = this.gridApi.getModel();

            let count = rowsToDisplay.reduce((count, row) => {
                if (row.data.bwgridCud === 'C') count++;
                return count;
            }, 0);

            return count;
        },

        /**
         * 수정된 행 수 구하기
         */
        getUpdatedRowCount() {
            let { rowsToDisplay } = this.gridApi.getModel();

            let count = rowsToDisplay.reduce((count, row) => {
                if (row.data.bwgridCud === 'U') count++;
                return count;
            }, 0);

            return count;
        },

        /**
         * 삭제된 행수 구하기
         */
        getDeletedRowCount() {
            return this.removedData.length;
        },
    }, // end methods

    data() {
        return {
            /** 그리드 ID */
            // bwGridId: null,

            /** 그리드 태그 */
            // bwGridTag: null,

            gridApi: null,
            gridColumnApi: null,

            /** 원본 데이터 */
            oriData: null,

            /** 삭제된 데이터 */
            removedData: [],

            // gridOptions: {},

            // components: {
            //     bwDateFilterComponent: BwDateFilterComponent,
            // },

            oriWidth: null, // Print시에 width와 height를 바꾸는데 Print 이후 원래대로 되도릴 값
            oriHeight: null, // Print시에 width와 height를 바꾸는데 Print 이후 원래대로 되도릴 값
        };
    }, // end data
};
</script>

<style lang="scss">
// @import '~ag-grid-community/dist/styles/ag-grid.css';
//@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
//@import "~ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
// @import "~ag-grid-community/dist/styles/ag-theme-balham.css";
// @import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";

// @import '~ag-grid-community/dist/styles/ag-grid.css';
// @import '~ag-grid-community/dist/styles/ag-theme-alpine.css';
// @import '~ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
// @import '~ag-grid-community/dist/styles/ag-theme-balham.css';
// @import '~ag-grid-community/dist/styles/ag-theme-balham-dark.css';
/* 그리드 헤더 영역 */
.bwgrid-header {
    /* 그리드 헤더의 좌측 */
    .bwgrid-header-left {
        padding-top: 6px;
        padding-left: 3px;

        /* 이미지 부분 */
        .bwgrid-header-img {
            vertical-align: middle;
            img {
            }
        }

        /* 타이틀 */
        .bwgrid-title {
        }
    }
    .bwgrid-buttons {
        /* Import 버튼 */
        .bwgrid-importexcel-btn {
        }
        /* Export Excel 버튼 */
        .bwgrid-exportexcel-btn {
        }
        /* Export PDF 버튼 */
        .bwgrid-exportpdf-btn {
        }
        /* Refresh 버튼 */
        .bwgrid-refresh-btn {
        }
        /* Restore 버튼 */
        .bwgrid-resore-btn {
        }
        /* Hide Column 버튼 */
        .bwgrid-hidecolumn-btn {
            &.active {
                background-color: orange;
            }
        }

        /* Add Row 버튼 */
        .bwgrid-addrow-btn {
        }
        /* Remove Row 버튼 */
        .bwgrid-removerow-btn {
        }

        /** Print 버튼 */
        .bwgrid-print-btn {
        }
    }
}

/* page 부분 */
.ag-paging-panel {
    justify-content: space-between;

    /* 선택된 행 */
    .bwgrid-selectedcount {
        cursor: pointer;
        text-decoration: underline;

        &.active {
            font-weight: bold;
        }
    }

    /* CUD 영역 */
    .bwgrid-cudcount {
        /* 추가된 행수 */
        .bwgrid-ccount {
            color: red;
            text-decoration: underline;
        }

        /* 수정된 행수 */
        .bwgrid-ucount {
            color: orange;
            text-decoration: underline;
        }

        /* 삭제된 행수 */
        .bwgrid-dcount {
            color: rebeccapurple;
        }

        /* 추가된 된 행만 보기 버튼 */
        .bwgrid-ccount {
            cursor: pointer;

            &.active {
                font-weight: bold;
            }
        }

        /* 추가된 된 행만 보기 버튼 */
        .bwgrid-ucount {
            cursor: pointer;

            &.active {
                font-weight: bold;
            }
        }
    }
}
</style>
