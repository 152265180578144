// @ is an alias to /src
import AlarmLayout from './AlarmLayout.vue'
import SettingLayout from './SettingLayout.vue'
import AvataLayout from './AvataLayout.vue'


export default {
  name: 'MainLayout',
  components: { 
    AlarmLayout, SettingLayout, AvataLayout
  },
  created() {
  },
  data: () => ({
  }),
}