import { render, staticRenderFns } from "./TreeviewForm2.vue?vue&type=template&id=0adc7962&"
import script from "./TreeviewForm2.vue?vue&type=script&lang=js&"
export * from "./TreeviewForm2.vue?vue&type=script&lang=js&"
import style0 from "./TreeviewForm2.vue?vue&type=style&index=0&id=0adc7962&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VAutocomplete,VChip})
