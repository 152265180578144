<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-img src="@/assets/sample_picture.jpg" max-width="300px" max-height="300" class="cursor-pointer" contain contain v-bind="attrs" v-on="on">
      </v-img>
      <!-- <v-img src="@/assets/camera-off-outline.png" max-height="200" contain></v-img> -->
    </template>
    <v-card>
      <v-toolbar dark color="primary" class="dialog-gallery-toolbar" height="56px">
        <v-toolbar-title class="align-center d-none d-sm-flex">
          <div class="d-flex flex-column">
            <div class="text-caption">Location</div>
            <strong>104-01-A-01</strong>
          </div>
          <v-divider vertical class="mx-5"></v-divider>
          <div class="d-flex flex-column">
            <div class="text-caption">Scan History</div>
            <strong>09/28/2022 00:00</strong>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-icon>mdi-text-box-search-outline</v-icon>
              </v-btn>
            </template>
            <v-card flat style="width:350px;">
              <v-toolbar flat>
                <v-toolbar-title>
                  <div class="d-flex flex-column">
                    <div class="text-caption">Location</div>
                    <strong>104-01-A-01</strong>
                  </div>
                </v-toolbar-title>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text class="pa-0">
                <v-row no-gutters class="align-center">
                  <v-col col="6" class="pa-0">
                    <div class="text-center pa-3">
                      <div class="text-caption">Status</div>
                      <v-chip class="chip-matched lighten-5 px-2">
                        Matched
                      </v-chip>
                      <!-- <v-chip class="chip-mismatched lighten-5 px-2">
                                Mismatched
                              </v-chip>
                              <v-chip class="chip-unidentified lighten-5 px-2">
                                Unidentified
                              </v-chip> -->
                    </div>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col col="6" class="pa-0">
                    <div class="text-center pa-3">
                      <div class="text-caption">Empty</div>
                      <v-chip class="px-2">
                        Y
                      </v-chip>
                      <!-- <v-chip class="px-2">
                                N
                              </v-chip> -->
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-title>
                <div class="subtitle-2">Scan History</div>
              </v-card-title>
              <v-card-text>
                <v-autocomplete v-model="value" :items="items" label="Date" hide-details="auto" readonly></v-autocomplete>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-title>
                <div class="subtitle-2">Scanned Information</div>
              </v-card-title>
              <v-card-text>
                <v-text-field label="LPN" value="140000" hide-details="auto" readonly class="mb-3"></v-text-field>
                <v-text-field label="SKU" value="-" hide-details="auto" readonly class="mb-3"></v-text-field>
                <v-text-field label="Product" value="-" hide-details="auto" readonly class="mb-3"></v-text-field>
                <v-text-field label="Quantity" value="-" hide-details="auto" readonly class="mb-3"></v-text-field>
                <v-text-field label="Manufacturing" value="-" hide-details="auto" readonly class="mb-3"></v-text-field>
                <v-text-field label="Expiration" value="-" hide-details="auto" readonly class="mb-3"></v-text-field>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-btn icon dark>
            <v-icon>mdi-magnify-plus-outline</v-icon>
          </v-btn>
          <v-btn icon dark>
            <v-icon>mdi-magnify-minus-outline</v-icon>
          </v-btn>
          <v-btn icon dark>
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
          <v-btn icon dark>
            <v-icon>mdi-share</v-icon>
          </v-btn>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card flat color="black" dark tile>
        <v-window v-model="onboarding">
          <v-window-item v-for="n in length" :key="`card-${n}`">
            <v-card color="transparent" style="height:calc(100vh - 96px);">
              <v-row class="fill-height" align="center" justify="center">
                <v-card-text class="text-center">
                  <v-img src="@/assets/sample_picture.jpg" class="img-width" contain></v-img>
                </v-card-text>
              </v-row>
            </v-card>
          </v-window-item>
        </v-window>

        <v-card-actions class="justify-space-between">
          <v-btn text @click="prev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-item-group v-model="onboarding" class="text-center" mandatory>
            <v-item v-for="n in length" :key="`btn-${n}`" v-slot="{ active, toggle }">
              <v-btn :input-value="active" icon @click="toggle">
                <v-icon>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
          <v-btn text @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>
  
<script>

export default {
  name: 'RegularScanPage',
  components: {
  },
  data: () => ({
    items: ['09/28/2022 00:00', '09/27/2022 00:00', '09/26/2022 00:00', '09/25/2022 00:00'],
    values: ['09/28/2022 00:00', '09/27/2022 00:00'],
    value: ['09/28/2022 00:00'],
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    length: 3,
    onboarding: 0,
  }),
  methods: {
    next() {
      this.onboarding = this.onboarding + 1 === this.length
        ? 0
        : this.onboarding + 1
    },
    prev() {
      this.onboarding = this.onboarding - 1 < 0
        ? this.length - 1
        : this.onboarding - 1
    },
  },
}
</script>

<style lang="scss">
.img-width .v-image__image {
  background-attachment: fixed;
}
</style>