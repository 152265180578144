import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthLayout from '../views/layout/AuthLayout.vue';
import MainLayout from '../views/layout/main/MainLayout.vue';

Vue.use(VueRouter);

export const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: AuthLayout,
            children: [
                {
                    path: '/',
                    name: 'Index',
                    component: () => import('../views/Index.vue'),
                },
                {
                    path: '/SignupAdmin',
                    name: 'SignupAdmin',
                    component: () => import('../views/auth/SignupAdmin.vue'),
                },
                {
                    path: '/SignupUser',
                    name: 'SignupUser',
                    component: () => import('../views/auth/SignupUser.vue'),
                },
                {
                    path: '/EmailIdentify',
                    name: 'EmailIdentify',
                    component: () => import('../views/auth/EmailIdentify.vue'),
                },
                {
                    path: '/CheckEmail',
                    name: 'CheckEmail',
                    component: () => import('../views/auth/CheckEmail.vue'),
                },
                {
                    path: '/ResetPassword',
                    name: 'ResetPassword',
                    component: () => import('../views/auth/ResetPassword.vue'),
                },
                {
                    path: '/WelcomeJoin',
                    name: 'WelcomeJoin',
                    component: () => import('../views/auth/WelcomeJoin.vue'),
                },
                {
                    path: '/ExistEmail',
                    name: 'ExistEmail',
                    component: () => import('../views/auth/ExistEmail.vue'),
                },
                {
                    path: '/ExpiredToken',
                    name: 'ExpiredToken',
                    component: () => import('../views/auth/ExpiredToken.vue'),
                },
                {
                    path: '/InvaildToken',
                    name: 'InvaildToken',
                    component: () => import('../views/auth/InvaildToken.vue'),
                },
            ],
        },
        {
            path: '/',
            component: MainLayout,
            children: [
                {
                    path: '/dashboard',
                    name: 'Dashboard',
                    component: () => import('../views/dashboard/Dashboard.vue'),
                },
                {
                    path: '/inventories',
                    name: 'Inventories',
                    component: () => import('../views/inventories/inventories.vue'),
                },
                {
                    path: '/discrepancies',
                    name: 'Discrepancies',
                    component: () => import('../views/discrepancies/discrepancies.vue'),
                },
                {
                    path: '/emptyLocation',
                    name: 'Empty Location',
                    component: () => import('../views/emptyLocation/emptyLocation.vue'),
                },
                {
                    path: '/LPNTracking',
                    name: 'LPN Tracking',
                    component: () => import('../views/LPNTracking/LPNTracking.vue'),
                },
                {
                    path: '/report',
                    name: 'Report',
                    component: () => import('../views/report/report.vue'),
                },
                /* {
          path: '/mission/regularScan',
          name: 'Regular Scan',
          component: () => import('../views/mission/regularScan/regularScan.vue'),
        },
        {
          path: '/mission/adHocScan',
          name: 'Ad Hoc Scan',
          component: () => import('../views/mission/adHocScan/adHocScan.vue'),
        },
        {
          path: '/mission/missionLog',
          name: 'Mission Log',
          component: () => import('../views/mission/missionLog/missionLog.vue'),
        },
        {
          path: '/mission/fleetStatus',
          name: 'Fleet Status',
          component: () => import('../views/mission/fleetStatus/fleetStatus.vue'),
        }, */
                {
                    path: '/VisualizerView',
                    name: '2D View',
                    component: () => import('../views/visualizer/VisualizerView.vue'),
                },
                {
                    path: '/VisualizerEdit',
                    name: '2D View Edit',
                    component: () => import('../views/visualizer/VisualizerEdit.vue'),
                },
                {
                    path: '/bulletin',
                    name: 'Bulletin',
                    component: () => import('../views/bulletin/bulletin.vue'),
                },
                {
                    path: '/notifications',
                    name: 'Notifications',
                    component: () => import('../views/notifications/notifications.vue'),
                },
                {
                    path: '/setting/adminMasterSetting',
                    name: 'Bware Setting',
                    component: () => import('../views/setting/adminMasterSetting.vue'),
                },
                {
                    path: '/setting/dataflowSetting',
                    name: 'Dataflow Setting',
                    component: () => import('../views/setting/dataflowSetting.vue'),
                },
                {
                    path: '/setting/dataflowSettingFlow',
                    name: 'Dataflow Setting Flow',
                    component: () => import('../views/setting/dataflowSettingFlow.vue'),
                },
                {
                    path: '/accountManagement',
                    name: 'Account Management',
                    component: () => import('../views/accountManagement/accountManagement.vue'),
                },
                {
                    path: '/setting/uploaddata',
                    name: 'WMS Data Upload',
                    component: () => import('../views/setting/uploaddata.vue'),
                },
                {
                    path: '/setting/apiReference',
                    name: 'API Reference',
                    component: () => import('../views/setting/apiReference.vue'),
                },
                {
                    path: '/account',
                    name: 'My Account',
                    component: () => import('../views/account/account.vue'),
                },
                {
                    path: '/setting/activity',
                    name: 'Activity',
                    component: () => import('../views/setting/activity.vue'),
                },

                {
                    path: '/sample/loginSample',
                    name: 'Login Sample',
                    component: () => import('../views/sample/loginSample.vue'),
                },
                {
                    path: '/sample/axiosSample',
                    name: 'Axios Sample',
                    component: () => import('../views/sample/axiosSample.vue'),
                },
                {
                    path: '/sample/storeSample',
                    name: 'Store Sample',
                    component: () => import('../views/sample/storeSample.vue'),
                },
                {
                    path: '/sample/axiosStoreSample',
                    name: 'Axios Store Sample',
                    component: () => import('../views/sample/axiosStoreSample.vue'),
                },

                {
                    path: '/sample/buttonSample',
                    name: 'Button Sample',
                    component: () => import('../views/sample/buttonSample.vue'),
                },
                {
                    path: '/sample/toastSample',
                    name: 'Toast Sample',
                    component: () => import('../views/sample/toastSample.vue'),
                },
                {
                    path: '/sample/spinnerSample',
                    name: 'Spinner Sample',
                    component: () => import('../views/sample/spinnerSample.vue'),
                },
                {
                    path: '/sample/multilingualSample',
                    name: 'Multilingual Sample',
                    component: () => import('../views/sample/multilingualSample.vue'),
                },
                {
                    path: '/sample/alertSample',
                    name: 'Alert / Confirm Sample',
                    component: () => import('../views/sample/alertSample.vue'),
                },
                {
                    path: '/sample/gridSample',
                    name: 'Grid Sample',
                    component: () => import('../views/sample/gridSample.vue'),
                },
                {
                    path: '/sample/dialogSample',
                    name: 'Dialog Sample',
                    component: () => import('../views/sample/dialogSample.vue'),
                },
                {
                    path: '/sample/datePickerSample',
                    name: 'Date Picker Sample',
                    component: () => import('../views/sample/datePickerSample.vue'),
                },
                {
                    path: '/sample/validatorSample',
                    name: 'Validator Sample',
                    component: () => import('../views/sample/validatorSample.vue'),
                },
            ],
        },
    ],
});

export default router;
