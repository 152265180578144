import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import VuetifyConfirm from 'vuetify-confirm';

Vue.use(VuetifyConfirm, {
    vuetify,
    buttonTrueText: 'Yes', // default: Yes
    buttonFalseText: 'No', // default: No
    buttonTrueColor: 'primary', // default: primary
    buttonFalseColor: 'grey', // default: grey
    buttonTrueFlat: false,
    buttonFalseFlat: true,
    color: 'warning', // default: warning
    // default: mdi-exclamation(warning)
    // mdi-close-circle / mdi-minus-box / mdi-checkbox-blank-outline / mdi-checkbox-marked / mdi-close / mdi-check / mdi-close-circle
    // mdi-circle / mdi-menu-down / mdi-pencil / mdi-alert / mdi-chevron-down / mdi-paperclip / mdi-page-first / mdi-information / mdi-page-last / mdi-cached
    // mdi-menu / mdi-minus / mdi-chevron-right / mdi-plus / mdi-chevron-left / mdi-radiobox-blank / mdi-radiobox-marked / mdi-star-outline / mdi-star / mdi-star-half-full
    // mdi-arrow-up / mdi-menu-down / mdi-check-circle / mdi-unfold-more-horizontal / mdi-exclamation"
    icon: 'mdi-information',
    title: ' ',
    width: 500,
    property: '$confirm',
    persistent: false,
});

export default {
    install(Vue) {
        //날짜 비교 함수
        Vue.prototype.$alert = async function (message, options) {
            await this.$confirm(
                message,
                Object.assign(
                    {
                        buttonTrueText: '', // 표시되지 않게 함
                        buttonFalseText: 'OK', // default: No
                        buttonFalseColor: 'primary', // default: grey
                        buttonFalseFlat: true,
                        color: 'warning',
                        icon: 'mdi-alert',
                        title: ' ',
                        width: 500,
                    },
                    options,
                ),
            );
        };
    },
};
