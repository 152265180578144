<template>
    <div class="sidepage-container">
        <SideTitle :title="'Fleet Status'">
            <template v-slot:BtnGroup> </template>
        </SideTitle>
        <v-container fluid class="sidepage-body">
            <div class="pa-3">
                <DroneTotal />
                <v-expansion-panels focusable class="fleet-status-panel mt-3">
                    <v-expansion-panel v-for="(item, i) in 5" :key="i">
                        <v-expansion-panel-header class="pa-2">
                            <!-- <v-icon color="success">mdi-circle</v-icon> -->
                            <v-icon color="error">mdi-circle</v-icon>
                            <div class="subtitle-2">Drone or Base station name</div>
                            <v-spacer></v-spacer>
                            <div class="text-h6">Idle</div>
                            <template v-slot:actions>
                                <v-icon color="warning">
                                    <!-- mdi-message-alert-outline -->
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pt-3">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn outlined> Initialize </v-btn>
                                <v-btn outlined> Detach Battery </v-btn>
                            </v-card-actions>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </v-container>
    </div>
</template>

<script>
import SideTitle from '@/components/SideTitle.vue';
import DroneTotal from '@/views/dashboard/DroneTotal.vue';

export default {
    name: 'RegularScanPage',
    components: {
        SideTitle,
        DroneTotal,
    },
    data: () => ({}),
};
</script>

<style lang="scss"></style>
