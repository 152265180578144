export default {
    title: {},

    label: {
        userId: '사용자 ID',
    },

    btn: {},

    message: {},
};
