<template>
    <div style="display: inline-block">
        <v-dialog v-model="visible" scrollable :width="width">
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                    <slot name="dialogLinkGroup"></slot>
                </div>
            </template>
            <v-card>
                <v-toolbar flat class="dialog-toolbar" height="56px">
                    <v-toolbar-title>
                        <slot name="dialogTitle"></slot>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <slot name="dialogContent"></slot>
                <v-divider></v-divider>
                <v-card-actions>
                    <div @click="close">
                        <slot name="dialogActionLeft"></slot>
                    </div>
                    <v-spacer></v-spacer>
                    <div @click="close">
                        <slot name="dialogActionRight"></slot>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'Dialog',
    components: {},
    props: {
        width: {
            type: String,
            default: 680 + 'px',
        },
    }, // end props

    beforeCreate() {}, // end beforeCreate
    created() {}, // end created
    beforeMounte() {}, //end beforeMounte
    mounted() {}, // end mounted
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    beforeDestroy() {}, // end beforeDestroy
    destroyed() {}, // end destroyed

    computed: {
        // src() {
        //     return '';
        // },
    }, // end computed

    watch: {},

    methods: {
        /** 아래쪽의 버튼 중 1개가 클릭될 때 */
        // onBottomButton() {
        //     console.log(11);
        //     alert(55);
        //     this.close();
        // },

        open() {
            this.visible = true;
        },
        close() {
            this.visible = false;
        },
    }, // end methods

    data: () => ({ visible: false }), // end data
};
</script>

<style lang="scss"></style>
