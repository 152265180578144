'use strict';

import Vue from 'vue';

/**
 * 공통 기능
 */
export default {
    /**
     * Dialog Open
     *     example: this.$dialog.open.call(this, 'dialog2');
     * @param {*} dialogRefId dialog Ref Id
     */
    open: function (dialogRefId) {
        this.$refs[dialogRefId].open();
    },

    /**
     * Dialog Close
     * @returns
     */
    close: function (e) {
        let vue = e.srcElement.__vue__ || e.srcElement.parentElement.__vue__;
        for (let i = 0; i < 100; i++) {
            if (!vue) break;

            if (vue.$options?._componentTag === 'bw-dialog') {
                vue.close();
                break;
            }

            vue = vue.$parent;
        }
    },
};
