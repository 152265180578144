<template>
    <div class="sidepage-container">
        <SideTitle :title="'Regular Scan'">
            <template v-slot:BtnGroup> </template>
        </SideTitle>
        <v-container fluid class="sidepage-body">
            <v-card flat>
                <v-card-text class="pt-1">
                    <v-text-field prepend-inner-icon="mdi-magnify" label="Search" hide-details="auto"> </v-text-field>
                </v-card-text>

                <v-card-text class="py-0">
                    <ListFilters>
                        <template v-slot:FilterLeft>
                            <div class="text-total">Total 54</div>
                        </template>
                        <template v-slot:FilterRight>
                            <v-btn dark color="cyan lighten-2" depressed small>
                                <v-icon left> mdi-plus </v-icon>
                                Add
                            </v-btn>
                            <v-divider vertical class="ma-3"></v-divider>
                            <FileDownload />
                        </template>
                    </ListFilters>
                </v-card-text>
                <v-divider></v-divider>

                <v-list class="pa-0">
                    <v-list-item-group v-model="selected" active-class="mission-list-selected">
                        <template v-for="(item, index) in items">
                            <v-list-item :key="item.title">
                                <template v-slot:default="{ active }">
                                    <template v-if="!active">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.title" class="text-caption"></v-list-item-title>
                                            <v-list-item-subtitle class="subtitle-2 text--primary mt-1" v-text="item.subtitle"> </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                    <template v-else>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.title" class="text-caption"></v-list-item-title>
                                            <v-list-item-subtitle class="subtitle-2 text--primary mt-1" v-text="item.subtitle"> </v-list-item-subtitle>
                                            <div class="list-action-button d-flex">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon small v-bind="attrs" v-on="on">
                                                            <v-icon> mdi-calendar-edit </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span> Edit Scan </span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon small class="ml-1" v-bind="attrs" v-on="on">
                                                            <v-icon> mdi-calendar-remove </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span> Remove Scan </span>
                                                </v-tooltip>
                                            </div>
                                            <div class="list-scan-location pa-3 mt-3">
                                                <div class="text-caption">Scan locations</div>
                                                <div class="body-1 mt-2" v-text="item.locations"></div>
                                                <div class="list-action-button d-flex">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon small v-bind="attrs" v-on="on">
                                                                <v-icon class="icon-image icon-toadhoc"> mdi-plus </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span> Ad Hoc Scan </span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-list-item>
                            <v-divider></v-divider>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import SideTitle from '@/components/SideTitle.vue';
import ListFilters from '@/components/ListFilters.vue';
import FileDownload from '@/components/agGrid/FileDownload.vue';

export default {
    name: 'RegularScanPage',
    components: {
        SideTitle,
        ListFilters,
        FileDownload,
    },
    data: () => ({
        selected: [2],
        items: [
            {
                locations: '0304, 0910, 1112',
                subtitle: `Daily 05:00`,
                title: 'Scan Name',
            },
            {
                locations: '0304, 0910, 1112',
                subtitle: `Daily 05:00`,
                title: 'Scan Name',
            },
            {
                locations: '0304, 0910, 1112',
                subtitle: `Daily 05:00`,
                title: 'Scan Name',
            },
            {
                locations: '0304, 0910, 1112, 0910, 1112, 0910, 1112, 0910, 1112, 0910, 1112, 0910, 1112, 0910, 1112, 0910, 1112',
                subtitle: `Daily 05:00`,
                title: 'Scan Name',
            },
        ],
    }),
};
</script>

<style lang="scss">
.list-action-button {
    position: absolute;
    top: 5px;
    right: 5px;
}

.list-scan-location {
    background-color: #ffffff !important;
    position: relative;
    z-index: 1;
    color: #000000;
}

.list-scan-location .text-caption {
    font-weight: 500;
}
</style>
