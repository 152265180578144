<template>
    <div>
        <!-- Alert.vue와 Confirm.vue 소스 동일함 -->
        <v-dialog v-model="dialog" width="300px">
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                    <slot name="dialogLinkGroup"></slot>
                </div>
            </template>
            <v-card>
                <v-card-text class="d-flex flex-column align-center text-center pa-5">
                    <v-avatar color="success" class="mb-2">
                        <v-icon dark> mdi-check </v-icon>
                    </v-avatar>
                    <!-- <v-avatar color="error" class="mb-2">
            <v-icon dark>
              mdi-close
            </v-icon>
          </v-avatar>
          <v-avatar color="warning" class="mb-2">
            <v-icon dark>
              mdi-exclamation
            </v-icon>
          </v-avatar> -->
                    <slot name="dialogMessageGroup"></slot>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="alert-card-actions pa-0">
                    <v-btn depressed tile @click="dialog = false"> Cancel </v-btn>
                    <v-btn depressed tile color="primary" @click="dialog = false"> Confirm </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'Confirm',
    components: {},
    data: () => ({
        dialog: null,
    }),
    computed: {},
};
</script>

<style lang="scss">
.alert-card-actions.v-card__actions {
    display: flex;
    flex-wrap: wrap;
}

.alert-card-actions.v-card__actions .v-btn {
    flex: 1 0 0%;
    width: 100%;
    max-width: 100%;
}

.v-application--is-ltr .alert-card-actions.v-card__actions > .v-btn.v-btn + .v-btn {
    margin-left: 0;
}
</style>
