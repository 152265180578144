// @ is an alias to /src
import TopMenu from './TopMenu.vue'
import LeftMenu from './LeftMenu.vue'
import RightMenu from './RightMenu.vue'
import RightContentLayout from './RightContentLayout.vue'

export default {
  name: 'MainLayout',
  components: { 
    TopMenu, LeftMenu, RightMenu, RightContentLayout
  },
  created() {
  },
  data: () => ({
    drawer: null,
    drawer2: null,
    mini: null,
  }),
}