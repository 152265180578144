<template>
    <div class="sidepage-container">
        <SideTitle :title="'Ad Hoc Scan'">
            <template v-slot:BtnGroup> </template>
        </SideTitle>
        <v-container fluid class="sidepage-body">
            <v-card flat>
                <v-card-text class="pt-1">
                    <div class="mt-2 mb-3">
                        <TreeviewForm2 />
                    </div>
                    <v-text-field prepend-inner-icon="mdi-magnify" label="Search" hide-details="auto"> </v-text-field>
                </v-card-text>

                <v-card-text class="py-0">
                    <ListFilters>
                        <template v-slot:FilterLeft>
                            <div class="text-total">Total 54</div>
                        </template>
                        <template v-slot:FilterRight>
                            <!--Dialog Preview Scan-->
                            <Dialog :width="1000">
                                <template v-slot:dialogLinkGroup>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon class="mr-2" color="info" small v-bind="attrs" v-on="on">
                                                <v-icon> mdi-data-matrix-scan </v-icon>
                                            </v-btn>
                                        </template>
                                        <span> Preview Scan</span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:dialogTitle> Preview Scan </template>
                                <template v-slot:dialogContent>
                                    <v-card-text class="py-3">
                                        <v-tabs vertical>
                                            <v-tab>
                                                <v-icon left small> mdi-quadcopter </v-icon>
                                                Base 1
                                            </v-tab>
                                            <v-tab>
                                                <v-icon left small> mdi-quadcopter </v-icon>
                                                Base 2
                                            </v-tab>
                                            <v-tab>
                                                <v-icon left small> mdi-quadcopter </v-icon>
                                                Base 3
                                            </v-tab>

                                            <v-tab-item>
                                                <v-card outlined>
                                                    <v-card-text>
                                                        <v-row justify="end">
                                                            <v-col cols="auto">
                                                                <span class="subheading font-weight-light mr-1">Max Cost</span>
                                                                <span class="text-h5 font-weight-bold" v-text="bpm"></span>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <v-slider v-model="bpm" :color="color" track-color="grey" hide-details always-dirty min="40" max="218">
                                                                    <template v-slot:prepend>
                                                                        <v-icon :color="color" @click="decrement"> mdi-minus </v-icon>
                                                                    </template>
                                                                    <template v-slot:append>
                                                                        <v-icon :color="color" @click="increment"> mdi-plus </v-icon>
                                                                    </template>
                                                                </v-slider>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                <v-btn :color="color" dark depressed small> Resend </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                    <v-divider></v-divider>
                                                    <v-tabs v-model="tab">
                                                        <v-tab v-for="item in tabitems" :key="item.tab">
                                                            {{ item.tab }}
                                                        </v-tab>
                                                    </v-tabs>
                                                    <v-divider></v-divider>
                                                    <v-tabs-items v-model="tab">
                                                        <v-tab-item v-for="item in tabitems" :key="item.tab">
                                                            <v-card flat>
                                                                <v-card-title>{{ item.title }}</v-card-title>
                                                                <v-card-text>{{ item.content }}</v-card-text>
                                                            </v-card>
                                                        </v-tab-item>
                                                    </v-tabs-items>
                                                </v-card>
                                            </v-tab-item>
                                            <v-tab-item>
                                                <v-card outlined> </v-card>
                                            </v-tab-item>
                                            <v-tab-item>
                                                <v-card outlined> </v-card>
                                            </v-tab-item>
                                        </v-tabs>
                                    </v-card-text>
                                </template>
                                <template v-slot:dialogActionRight>
                                    <v-btn text @click="dialog = false"> Close </v-btn>
                                </template>
                            </Dialog>
                            <!-- <v-btn color="error" depressed small>
                                <v-icon left> mdi-stop-circle </v-icon>
                                Scanning Stop
                            </v-btn> -->
                            <v-btn dark color="primary" depressed small>
                                <v-icon left> mdi-motion-play-outline </v-icon>
                                Start Scan
                            </v-btn>
                            <v-divider vertical class="ma-2"></v-divider>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon small v-bind="attrs" v-on="on">
                                        <v-icon> mdi-trash-can-outline </v-icon>
                                    </v-btn>
                                </template>
                                <span> Delete All </span>
                            </v-tooltip>
                        </template>
                    </ListFilters>
                </v-card-text>
                <v-divider></v-divider>

                <v-list class="pa-0">
                    <v-list-item-group v-model="selected" active-class="mission-list-selected2">
                        <template v-for="(item, index) in items">
                            <v-list-item :key="item.title">
                                <!-- 기본 class 없음-->
                                <!-- <v-list-item :key="item.title" class="bg-mission-waiting"> 스캔대기 class
              <v-list-item :key="item.title" class="bg-mission-working"> 스캔중 class
              <v-list-item :key="item.title" class="bg-mission-complete"> 스캔완료 class -->
                                <template v-slot:default="{ active }">
                                    <template v-if="!active">
                                        <v-list-item-content>
                                            <v-list-item-title class="f-flex align-center">
                                                <span v-text="item.locations" class="subtitle-1 text--primary font-weight-medium mr-2"></span>
                                                <v-chip class="chip-list chip-matched lighten-5 px-2"> Matched </v-chip>
                                                <!-- <v-chip class="chip-list chip-mismatched lighten-5 px-2">
                          Mismatched
                        </v-chip>
                        <v-chip class="chip-list chip-unidentified lighten-5 px-2">
                          Unidentified
                        </v-chip> -->
                                            </v-list-item-title>
                                            <v-list-item-subtitle>LPN <span v-text="item.lpn"></span></v-list-item-subtitle>
                                            <v-list-item-subtitle>Scanned<span v-text="item.scanned"></span></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                    <template v-else>
                                        <v-list-item-content>
                                            <v-list-item-title class="f-flex align-center">
                                                <span v-text="item.locations" class="subtitle-1 text--primary font-weight-medium mr-2"></span>
                                                <v-chip class="chip-list chip-matched lighten-5 px-2"> Matched </v-chip>
                                                <!-- <v-chip class="chip-list chip-mismatched lighten-5 px-2">
                          Mismatched
                        </v-chip>
                        <v-chip class="chip-list chip-unidentified lighten-5 px-2">
                          Unidentified
                        </v-chip> -->
                                            </v-list-item-title>
                                            <v-list-item-subtitle>LPN <span v-text="item.lpn"></span></v-list-item-subtitle>
                                            <v-list-item-subtitle>Scanned<span v-text="item.scanned"></span></v-list-item-subtitle>
                                            <div class="list-action-button d-flex">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon small v-bind="attrs" v-on="on">
                                                            <v-icon> mdi-archive-search-outline </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span> Location Detail </span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon small class="ml-1" v-bind="attrs" v-on="on">
                                                            <v-icon> mdi-trash-can-outline </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span> Remove Location </span>
                                                </v-tooltip>
                                            </div>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-list-item>
                            <v-divider></v-divider>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import SideTitle from '@/components/SideTitle.vue';
import ListFilters from '@/components/ListFilters.vue';
import TreeviewForm2 from '@/components/TreeviewForm2.vue';
import Dialog from '@/components/Dialog.vue';

export default {
    name: 'RegularScanPage',
    components: {
        SideTitle,
        ListFilters,
        TreeviewForm2,
        Dialog,
    },
    data: () => ({
        selected: [2],
        items: [
            {
                locations: '0708-A-02',
                lpn: '100000',
                scanned: '2022-05-12',
            },
            {
                locations: '0708-A-02',
                lpn: '100000',
                scanned: '2022-05-12',
            },
            {
                locations: '0708-A-02',
                lpn: '100000',
                scanned: '2022-05-12',
            },
            {
                locations: '0708-A-02',
                lpn: '100000',
                scanned: '2022-05-12',
            },
            {
                locations: '0708-A-02',
                lpn: '100000',
                scanned: '2022-05-12',
            },
        ],
        tab: null,
        tabitems: [
            { tab: 'Group One', title: 'Tab Title', content: 'Tab 1 Content' },
            { tab: 'Group Two', title: 'Tab Title', content: 'Tab 2 Content' },
            { tab: 'Group Three', title: 'Tab Title', content: 'Tab 3 Content' },
            { tab: 'Group Four', title: 'Tab Title', content: 'Tab 4 Content' },
            { tab: 'Group Five', title: 'Tab Title', content: 'Tab 5 Content' },
            { tab: 'Group Six', title: 'Tab Title', content: 'Tab 6 Content' },
            { tab: 'Group Seven', title: 'Tab Title', content: 'Tab 7 Content' },
            { tab: 'Group Eight', title: 'Tab Title', content: 'Tab 8 Content' },
            { tab: 'Group Nine', title: 'Tab Title', content: 'Tab 9 Content' },
            { tab: 'Group Ten', title: 'Tab Title', content: 'Tab 10 Content' },
        ],
        bpm: 40,
        interval: null,
    }),
    computed: {
        color() {
            if (this.bpm < 100) return 'indigo';
            if (this.bpm < 125) return 'teal';
            if (this.bpm < 140) return 'green';
            if (this.bpm < 175) return 'orange';
            return 'red';
        },
        animationDuration() {
            return `${60 / this.bpm}s`;
        },
    },

    methods: {
        decrement() {
            this.bpm--;
        },
        increment() {
            this.bpm++;
        },
    },
};
</script>

<style lang="scss">
.list-action-button {
    position: absolute;
    top: 5px;
    right: 5px;
}

.chip-list {
    position: relative;
    z-index: 1;
}
</style>
