<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dateRange"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="formatted"
                :label="isNil(label) ? 'Date range' : label"
                :prepend-inner-icon="
                    prependInnerIcon === null || prependInnerIcon === undefined
                        ? 'mdi-calendar-blank-outline'
                        : prependInnerIcon
                "
                hide-details="auto"
                v-bind="{ ...$props, ...$attrs }"
                v-on="on"
                readonly
            ></v-text-field>
        </template>
        <v-date-picker v-model="strDateRange" range v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="onClickCancel"> Cancel </v-btn>
            <v-btn text color="primary" @click="onClickOk"> OK </v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
    name: 'BwDateRangePicker',
    components: {},

    props: {
        label: {},
        value: {
            type: Array,
            default: 0,
        },
        format: {
            type: String,
        },
        prependInnerIcon: {},
        // clearVisible: {
        //     type: Boolean,
        //     default: false,
        // },
    },
    emits: ['update:value'],

    beforeCreate() {}, // end beforeCreate
    created() {}, // end created

    beforeMounte() {}, //end beforeMounte

    mounted() {
        this.strFormat = this.format;
        if (_.isEmpty(this.strFormat)) {
            this.strFormat = this.$t('common.dateFormat');
        }

        if (_.isEmpty(this.value)) {
            // YYYY-MM-DD
            // let todayYYYYMMDD = new Date().toISOString().replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, '$1-$2-$3');
            // this.dateRange = [Date.now(), date.now()];
        } else {
            this.dateRange = this.value;
        }

        // 내부적으로는 YYYY-MM-DD를 사용
        this.strDateRange = [
            !_.isNil(this.dateRange[0]) && this.dateRange[0] >= 0 ? moment(this.dateRange[0]).format('YYYY-MM-DD') : '',
            !_.isNil(this.dateRange[1]) && this.dateRange[1] >= 0 ? moment(this.dateRange[1]).format('YYYY-MM-DD') : '',
        ];
    }, // end mounted

    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    beforeDestroy() {}, // end beforeDestroy
    destroyed() {}, // end destroyed

    computed: {
        formatted() {
            let dateRange2 = [
                !_.isNil(this.dateRange[0]) && this.dateRange[0] >= 0
                    ? moment(this.dateRange[0]).format(this.strFormat)
                    : '',
                !_.isNil(this.dateRange[1]) && this.dateRange[1] >= 0
                    ? moment(this.dateRange[1]).format(this.strFormat)
                    : '',
            ];
            return dateRange2.join(' ~ ');
        },
    }, //end computed

    watch: {}, // end watch

    methods: {
        isNil(value) {
            return _.isNil(value);
        },

        onClickCancel() {
            this.menu = false;

            // 내부적으로는 YYYY-MM-DD를 사용
            this.strDateRange = [
                !_.isNil(this.dateRange[0]) && this.dateRange[0] >= 0
                    ? moment(this.dateRange[0]).format('YYYY-MM-DD')
                    : '',
                !_.isNil(this.dateRange[1]) && this.dateRange[1] >= 0
                    ? moment(this.dateRange[1]).format('YYYY-MM-DD')
                    : '',
            ];
        },

        onClickOk() {
            // let date = moment(this.dates[0]); // 날짜 형식이 맞는지 체크하기 위함
            // if (!date._isValid) return;

            // date = moment(this.dates[1]); // 날짜 형식이 맞는지 체크하기 위함
            // if (!date._isValid) return;

            this.dateRange = [moment(this.strDateRange[0]).valueOf(), moment(this.strDateRange[1]).valueOf()];
            this.$refs.menu.save(this.dateRange);
            this.$emit('update:value', this.dateRange);
        },
    }, // end methods

    data: () => ({
        dateRange: [Date.now(), Date.now()], // 숫자 13자리 형태
        strDateRange: [], // v-date-picker의 v-model이 String임(format: YYYY-MM-DD)
        strFormat: null,
        menu: false,
    }),
};
</script>
