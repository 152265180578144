import { make } from 'vuex-pathify';

const state = {
    sampleData: {
        key1: 'Data1',
        key2: 'Data22',
    },
    sampleData2: {
        key1: 'Data1',
        key2: 'Data33',
    },
};

const mutations = make.mutations(state);

const actions = {
    ...make.actions(state),
    init: async ({ dispatch }) => {},
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
