<template>
    <div>
        <v-window>
            <v-window-item>
                <v-sheet class="code-sheet outlined overflow-hidden my-0">
                    <!-- eslint-disable  -->
                    <pre><code class="language-markup" :id="uuid"><slot name="content"></slot></code></pre>
                    <v-btn icon class="btn-code-copy">
                        <v-icon @click="copy"> mdi-content-copy </v-icon>
                    </v-btn>
                </v-sheet>
            </v-window-item>
        </v-window>
    </div>
</template>

<script>
export default {
    name: 'BwCodeSheet',
    components: {},
    props: {}, // end props

    beforeCreate() {}, // end beforeCreate
    created() {}, // end created
    beforeMounte() {}, //end beforeMounte
    mounted() {
        this.uuid = this.$common.createUUID();
    }, // end mounted

    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    beforeDestroy() {}, // end beforeDestroy
    destroyed() {}, // end destroyed
    computed: {}, //end computed
    watch: {}, // end watch

    methods: {
        copy() {
            console.log(this.uuid);
            this.$common.copyTagText(this.uuid);
        },
    }, // end methods

    data: () => ({
        uuid: null,
    }), // end data
};
</script>
