// @ is an alias to /src
import * as authApi from '@/api/authApi';
import router from '@/router';

export default {
    name: 'AvataLayout',
    components: {},

    methods: {
        logout() {
            authApi.logout().then((response) => {
                router.push('/');
            });
        },
    },
    data: () => ({
        user: {
            initials: 'JD',
            fullName: 'John Doe',
            email: 'john.doe@doe.com',
        },
    }),
};
