import { render, staticRenderFns } from "./DroneTotal.vue?vue&type=template&id=6efad976&"
import script from "./DroneTotal.vue?vue&type=script&lang=js&"
export * from "./DroneTotal.vue?vue&type=script&lang=js&"
import style0 from "./DroneTotal.vue?vue&type=style&index=0&id=6efad976&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VIcon})
