import http from './http';
import Vue from 'vue';
import store from '@/store';

export async function login(userid, password, config) {
    return http
        .post(
            '/login',
            {
                userid,
                password,
            },
            Object.assign({ responseCommonError: false }, config),
        )
        .then((response) => {
            store.commit('auth/loginUser', response.data.user);
            return response;
        })
        .catch((error) => {
            console.error(error);
            Vue.$toast.error(error.response.data?.errorMsg || 'Failed Login.');
            return Promise.reject(error);
        });
}

export async function logout(config) {
    store.commit('auth/loginUser', null);
    return http.get('/logout', config);
}
