'use strict';

import Vue from 'vue';

/**
 * 공통 기능
 */
export default {
    required(value, errorMsg) {
        return !!(value + '') || errorMsg || 'Required.';
    },

    email(value, errorMsg) {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || errorMsg || 'Invalid e-mail.';
    },

    pattern(value, pattern, errorMsg) {
        return pattern.test(value) || errorMsg || 'Invalid.';
    },
};
