<template>
    <div>
        <bw-date-picker
            prepend-inner-icon=""
            label=""
            solo
            outlined
            dense
            :value.sync="date13"
            :clearable="true"
        ></bw-date-picker>
    </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
    beforeCreate() {}, // end beforeCreate
    created() {}, // end created
    beforeMounte() {}, //end beforeMounte
    mounted() {
        setTimeout(() => {
            // Filter Icon 안보이게
            this.$el.parentElement.parentElement.getElementsByClassName('ag-floating-filter-button')[0].style.display =
                'none';
        });
    }, // end mounted

    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    beforeDestroy() {}, // end beforeDestroy
    destroyed() {}, // end destroyed
    computed: {}, //end computed

    watch: {
        date13(newVal, oldVal) {
            this.params.parentFilterInstance((instance) => {
                if (_.isNil(newVal)) {
                    // Filtering 제거
                    instance.onFloatingFilterChanged(null, null);
                } else {
                    // equals로 해야 onParentModelChanged()가 호출된다(inRange로 하면 호출 안됨)
                    // onParentModelChanged()에서 dateTo과 dateTo를 지정함
                    instance.onFloatingFilterChanged('equals', new Date(newVal));
                }
            });
        },
    }, // end watch

    methods: {
        onParentModelChanged(parentModel) {
            if (!parentModel) return;

            // let date = moment(this.date13);

            // inRange는 앞뒤로 equal이 아님
            // 2023-06-30일 filtering시 dateFrom이 2023-06-29 23:59:59로 되기 때문에 '2023-06-29 23:59:59 001' 과 같은 시간이 조회될 수 있음
            parentModel.type = 'inRange';
            parentModel.dateFrom = moment(this.date13 - 1).format('YYYY-MM-DD HH:mm:ss');
            // parentModel.datetTo = parentModel.dateFrom;
            parentModel.dateTo = moment(this.date13 + 1000 * 60 * 60 * 24).format('YYYY-MM-DD');
        },

        afterGuiAttached(params) {
            // 커스텀 필터가 DOM에 첨부된 후 실행되는 로직 작성
            // console.log('afterGuiAttached');
        },
    }, // end methods

    data() {
        return {
            date13: null, // filtering할 날짜
        };
    },
};
</script>

<style lang="scss">
.ag-floating-filter-body {
    .v-input.v-text-field {
        margin-top: 7px;
        .v-input__control {
            height: 18px;
            min-height: 18px;
            .v-input__slot {
                height: 18px;
                min-height: 18px;
                input {
                    color: rgba(0, 0, 0, 0.54);
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
