<template>
  <div class="list-filter">
    <v-row no-gutters class="align-center mb-1">
      <v-col cols="auto">
        <div class="d-flex align-center">
          <slot name="FilterLeft"></slot>
        </div>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <div class="d-flex align-center">
          <slot name="FilterRight"></slot>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'gridFilters',
  components: {
  },
  data() {
    return {
      columnSettings: [],
    }
  },
};
</script>