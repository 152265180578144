import axios from 'axios';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css'; // this.$toast.open() 처럼 사용할 수 있게 함

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import Title from './Title.vue';
import Search from './Search.vue';
import SearchFilter from './SearchFilter.vue';
import AgGrid from './agGrid/agGrid.vue';
import GridFilters from './agGrid/gridFilters.vue';
import datePicker from './datePicker.vue';
import timePicker from './timePicker.vue';
import BwDateRangePicker from '@/components/BwDateRangePicker.vue';
import BwDatePicker from '@/components/BwDatePicker.vue';
import BwTimePicker from '@/components/BwTimePicker.vue';
import BwDialog from './BwDialog.vue';
import BwCodeSheet from './BwCodeSheet.vue';
import Alert from './Alert.vue';

// import Tree from './EqpTree/Tree'
// import EqpTree from './EqpTree/EqpTree'
import BwGrid from './bwgrid/BwGrid';
// import FdcChart from './FdcChart/FdcChart'
// import DyChart from './Dygraphs/Dygraph'
// import ComponentPanel from './ComponentPanel/ComponentPanel'
// import FdcPanel from './FdcPanel/FdcPanel'
// import Toggle from './Toggle/Toggle'
// import { Splitpanes, Pane } from 'splitpanes'
import bwBtn from './BwBtn';
import BwSpinner from './BwSpinner';
// import BeModal from './BeModal/BeModal.vue'
// import BeChart from './BeChart/BeChart.vue'

export default {
    install(Vue, options) {
        Vue.prototype.$axios = axios; //this.$axios
        axios.defaults.headers.get['Pragma'] = 'no-cache';
        axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

        Vue.use(VueToast, {
            position: 'top',
        });

        Vue.component('title_', Title);
        Vue.component('search', Search);
        Vue.component('searchFilter', SearchFilter);
        Vue.component('agGrid', AgGrid);
        Vue.component('gridFilters', GridFilters);
        Vue.component('datePicker', datePicker); //나중에 지울 것
        Vue.component('timePicker', timePicker); // 나중에 지울 것
        Vue.component('bw-date-range-picker', BwDateRangePicker);
        Vue.component('bw-date-picker', BwDatePicker);
        Vue.component('bw-time-picker', BwTimePicker);
        Vue.component('bw-dialog', BwDialog);
        Vue.component('bw-code-sheet', BwCodeSheet);
        Vue.component('alert', Alert);
        // Vue.component('tree', Tree)
        // Vue.component('eqp-tree', EqpTree)
        Vue.component('bw-grid', BwGrid);
        // Vue.component('fdc-chart', FdcChart)
        // Vue.component('dy-chart', DyChart)
        // Vue.component('component-panel', ComponentPanel)
        // Vue.component('fdc-panel', FdcPanel)
        // Vue.component('toggle', Toggle)
        // Vue.component('splitpanes', Splitpanes)
        // Vue.component('pane', Pane)
        Vue.component('bw-btn', bwBtn);
        Vue.component('bw-spinner', BwSpinner);
        // Vue.component('be-loading', BwSpinner)
        // Vue.component('be-modal', BeModal)
        // Vue.component('be-chart', BeChart)
    },
};
