<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="time"
                label="Time"
                :prepend-inner-icon="
                    prependInnerIcon === null || prependInnerIcon === undefined
                        ? 'mdi-clock-time-four-outline'
                        : prependInnerIcon
                "
                hide-details="auto"
                v-bind="{ ...$props, ...$attrs }"
                v-on="on"
            ></v-text-field>
        </template>
        <v-time-picker v-model="time" full-width v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="onClickOk"> OK </v-btn>
        </v-time-picker>
    </v-menu>
</template>

<script>
export default {
    name: 'BwTimePicker',
    components: {},

    props: ['value', 'prependInnerIcon'],
    emits: ['update:value'],

    beforeCreate() {}, // end beforeCreate
    created() {}, // end created

    mounted() {
        if (_.isEmpty(this.value)) {
            // YYYY-MM-DD
            this.time = '00:00';
        } else {
            this.time = this.value;
        }
    }, // end mounted

    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    beforeDestroy() {}, // end beforeDestroy
    destroyed() {}, // end destroyed
    computed: {}, //end computed
    watch: {}, // end watch

    methods: {
        onClickOk() {
            this.$refs.menu.save(this.time);
            this.$emit('update:value', this.time);
        },
    }, // end methods

    data() {
        return {
            time: null,
            menu: false,
        };
    },
};
</script>
