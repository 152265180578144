<template>
  <v-card elevation="1" class="totaldrone-card">
    <div class="totaldrone-header d-flex justify-center align-center">
      <div class="text-title">
        Total drone
      </div>
      <div class="text-count">
        3
      </div>
    </div>

    <div class="totaldrone-body d-flex justify-center align-center">
      <div class="totaldrone-detail d-flex flex-column justify-center text-center">
        <div class="text-title">
          Available
        </div>
        <div class="text-count">
          3
        </div>
      </div>
      <v-icon large color="blue darken-4">
        mdi-plus
      </v-icon>
      <div class="totaldrone-detail d-flex flex-column justify-center text-center">
        <div class="text-title">
          In flight
        </div>
        <div class="text-count">
          0
        </div>
      </div>
      <v-icon large color="blue darken-4">
        mdi-plus
      </v-icon>
      <div class="totaldrone-detail d-flex flex-column justify-center text-center">
        <div class="text-title">
          Out of service
        </div>
        <div class="text-count">
          0
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
    }
  },
}
</script>

<style lang="scss">
.totaldrone-header {
  padding: 10px;
  height: 120px;
}

.totaldrone-header .text-title {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.totaldrone-header .text-count {
  font-size: 46px;
  font-weight: 500;
  margin-left: 15px;
}

.theme--light .totaldrone-header .text-count {
  color: #1E88E5;
}

.theme--dark .totaldrone-header .text-count {
  color: #1E88E5;
}

.totaldrone-body {
  color: #ffffff;
  padding: 10px;
}

.theme--light .totaldrone-body {
  background-color: #1565C0;
}

.theme--dark .totaldrone-body {
  background-color: #1565C0;
}

.totaldrone-detail {
  height: 80px;
}

.totaldrone-detail .text-title {
  font-size: 11px;
  text-transform: uppercase;
}

.totaldrone-detail .text-count {
  font-size: 28px;
  font-weight: 500;
}
</style>