import { make, set } from 'vuex-pathify';
import * as sampleApi from '@/api/sampleApi';

const state = {
    receivedData: [],
};

const mutations = make.mutations(state);

const actions = {
    ...make.actions(state),
    init: async ({ dispatch }) => {},

    fetchBulletinsForMainPage(context, payload) {
        return sampleApi.fetchBulletinsForMainPage().then((response) => {
            context.commit('receivedData', response.data);
        });
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
