<template>
    <div>
        <router-view class="v-application-bgarage" />
        <bw-spinner :is-global="true" ref="gSpinner" :visible.sync="gSpinnerVisible" />
    </div>
</template>

<script>
import '@/styles/overrides.sass';

export default {
    name: 'App',
    data: () => ({
        gSpinnerVisible: false,
    }),
};
</script>

<style lang="scss">
// @import '@/ag-grid-community/styles/ag-grid.css';
// @import '@/ag-grid-community/styles/ag-theme-alpine.css';
</style>
