import { render, staticRenderFns } from "./Title.vue?vue&type=template&id=20b2aa4a&"
import script from "./Title.js?vue&type=script&lang=js&"
export * from "./Title.js?vue&type=script&lang=js&"
import style0 from "./Title.vue?vue&type=style&index=0&id=20b2aa4a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBreadcrumbs,VCol,VDivider,VRow,VToolbar})
