'use strict';

import Vue from 'vue';

/**
 * 공통 기능
 */
export default {
    /**
     * UUID 생성
     * @returns
     */
    createUUID: function () {
        // http://www.ietf.org/rfc/rfc4122.txt
        var s = [];
        var hexDigits = '0123456789abcdef';
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = '-';

        var uuid = s.join('');
        return uuid;
    },

    /**
     * Dom의 값을 Clipboard에 Copy
     * @param {*} tagId
     */
    copyFormValue: function (tagId) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(document.getElementById(tagId).value);
        } else {
            this._copyUseTextarea(tagId, document.getElementById(tagId).value);
        }

        Vue.$toast.info('Copied to clipboard.');
    },

    /**
     * Dom의 Text를 Clipboard에 Copy
     * @param {*} tagId
     */
    copyTagText: function (tagId) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(document.getElementById(tagId).innerText);
        } else {
            this._copyUseTextarea(tagId, document.getElementById(tagId).innerText);
        }

        Vue.$toast.info('Copied to clipboard.');
    },

    /**
     * Dom의 Html을 Clipboard에 Copy
     * @param {*} tagId
     */
    copyTagHtml: function (tagId) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(document.getElementById(tagId).innerHTML);
        } else {
            this._copyUseTextarea(tagId, document.getElementById(tagId).innerHTML);
        }

        Vue.$toast.info('Copied to clipboard.');
    },

    _copyUseTextarea(tagId, value) {
        let textArea = document.createElement('textarea');
        textArea.value = value;

        //팝업 창일 때 body에 textArea를 추가해도 복사가 되지 않는 경우 발생
        //이를 보완하기 위해 코드 수정, 이전코드 : document.body.appendChild(textArea);
        let tagParent = document.getElementById(tagId).parentElement;
        tagParent.appendChild(textArea);

        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '0';

        // 팝업 창일 때 복사가 안되는 경우를 방지하기 위해 추가
        textArea.style.width = '0';
        textArea.style.height = '0';

        textArea.style.position = 'fixed';

        textArea.select();

        try {
            let successful = document.execCommand('copy');
            let msg = successful ? 'successful' : 'unsuccessful';
            Vue.$toast.info('Copied to clipboard.');
            // console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        //팝업 창일 때 body에 textArea를 추가해도 복사가 되지 않는 경우 발생
        //이를 보완하기 위해 코드 수정, 이전코드 : document.body.removeChild(textArea);
        tagParent.removeChild(textArea);
    },

    test_getMaxAndTickForChart(testMax = 10000, inc = 1) {
        for (let i = 1; i < testMax; i += inc) {
            let { tick, max } = this.getMaxAndTickForChart(i);

            console.log(i, i * 1.1, max, tick, `tick수: ${max / tick}`);
            if (isNaN(tick)) {
                console.log('잘못됨', i, max, tick); // 잘못 구한 경우 없음
            }
        }
    },

    /**
     * Chart의 max, tick 값 계산 // ex) n => { max, tick } // 1 => { 10, 2 }, 9 => { 10, 2 }, 11 => { 16, 4 }, 51 => { 60, 10 }, 91 => { 120, 20}
     * @param {*} nCeil
     * @returns { max, tick }
     */
    getMaxAndTickForChart: function (n) {
        n = n * 1.1;
        let nCeil = Math.ceil(n); // 소수점 제거

        let len = nCeil.toString().length; // 자릿수 구하기
        let pow10 = Math.pow(10, len - 1); // 4자리: 1000, 3자리: 100, 2자리 : 10, 1자리: 1
        let max =
            nCeil > 10
                ? Math.ceil(nCeil / pow10) * pow10 // 100이하 일의 자리만 자름
                : 10;
        let maxLen = max.toString().length;
        let maxPow10 = Math.pow(10, maxLen - 2); // 4자리: 100, 3자리: 10, 2자리: 1, 1자리의 경우는 없음
        let maxFirst = Number(max.toString().charAt(0));

        // max에 따른 tick 지정
        // [0]: max, [1]: tick, 10 => 2, 20 => 4, ... 90 => 15
        let maxTicks = [
            [1, 2],
            [2, 4],
            [3, 5],
            [4, 5],
            [5, 10],
            [6, 10],
            [7, 10],
            [8, 10],
            [9, 15],
        ];
        let [tempMax, tick] = maxTicks.find((item) => item[0] === maxFirst);

        tick *= maxPow10;

        if (max > 10) {
            while (max > n + tick) {
                // 99의 max가 200으로 나오는데
                max -= tick; // max를 120이 되게 함
            }

            if (max / tick <= 3) {
                // Tick이 3개밖에 안나오면, maxTicks에서 tick이 6개 이상인 것은 이 로직을 타지 않음(따라서 '[4, 5]' 처럼 tick이 홀수 가능)
                tick /= 2; // 6개로 늘려줌
            }
        }

        return { max, tick };
    },
    /**
     * 현재 연도
     * @param {String} thisYearClass ex) 'thisYear'
     */
    getCurrYear() {
        return new Date().getFullYear(); // 년도
    },
    //로딩바 보이기
    ShowLoading() {
        $('.body-loading-wrap').removeClass('sr-only');
    },
    //로딩바 숨기기
    HideLoading() {
        $('.body-loading-wrap').addClass('sr-only');
    },
    /**
     * 선택한 row의 색을 바꿈
     */
    highlightRow: function (tr) {
        $(tr).addClass('table-info');
    },
    /**
     * 선택한 row이외의 색을 원상 복귀
     */
    clearHighlightRows: function (tr) {
        $(tr).siblings().addBack().removeClass('table-info');
    },
};
