<template>
  <div class="sidepage-container">
    <v-toolbar flat class="sidepage-toolbar">
      <v-toolbar-title>
        <div class="d-flex flex-column">
          <div class="text-caption">Location</div>
          <strong>104-01-A-01</strong>
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container fluid class="sidepage-body">
      <v-card flat>
        <v-card-text class="pa-0">
          <v-row no-gutters class="align-center">
            <v-col col="6" class="pa-0">
              <div class="text-center pa-3">
                <div class="text-caption">Status</div>
                <v-chip class="chip-matched lighten-5 px-2">
                  Matched
                </v-chip>
                <!-- <v-chip class="chip-mismatched lighten-5 px-2">
                Mismatched
              </v-chip>
              <v-chip class="chip-unidentified lighten-5 px-2">
                Unidentified
              </v-chip> -->
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col col="6" class="pa-0">
              <div class="text-center pa-3">
                <div class="text-caption">Empty</div>
                <v-chip class="px-2">
                  Y
                </v-chip>
                <!-- <v-chip class="px-2">
                N
              </v-chip> -->
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title>
          <div class="subtitle-2">Scan History</div>
        </v-card-title>
        <v-card-text>
          <v-autocomplete v-model="value" :items="items" label="Date" hide-details="auto"></v-autocomplete>
          <div class="mt-3">
            <locationDetailGallery />
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title>
          <div class="subtitle-2">Scanned Information</div>
        </v-card-title>
        <v-card-text>
          <v-text-field label="LPN" value="140000" hide-details="auto" readonly class="mb-3"></v-text-field>
          <v-text-field label="SKU" value="-" hide-details="auto" readonly class="mb-3"></v-text-field>
          <v-text-field label="Product" value="-" hide-details="auto" readonly class="mb-3"></v-text-field>
          <v-text-field label="Quantity" value="-" hide-details="auto" readonly class="mb-3"></v-text-field>
          <v-text-field label="Manufacturing" value="-" hide-details="auto" readonly class="mb-3"></v-text-field>
          <v-text-field label="Expiration" value="-" hide-details="auto" readonly class="mb-3"></v-text-field>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
  
<script>
import locationDetailGallery from './locationDetailGallery.vue'

export default {
  name: 'RegularScanPage',
  components: {
    locationDetailGallery
  },
  data: () => ({
    items: ['09/28/2022 00:00', '09/27/2022 00:00', '09/26/2022 00:00', '09/25/2022 00:00'],
    values: ['09/28/2022 00:00', '09/27/2022 00:00'],
    value: ['09/28/2022 00:00'],
    notifications: false,
    sound: true,
    widgets: false,
    length: 3,
    onboarding: 0,
  }),
  methods: {
    next() {
      this.onboarding = this.onboarding + 1 === this.length
        ? 0
        : this.onboarding + 1
    },
    prev() {
      this.onboarding = this.onboarding - 1 < 0
        ? this.length - 1
        : this.onboarding - 1
    },
  },
}
</script>
  