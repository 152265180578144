export default {
    title: {
        sampleTitle: 'Sample Title',
    },

    label: {},

    btn: {},

    message: {
        sampleMessage: 'Sample Message.',
    },
};
