<template>
  <div>
    <h6 class="text-treeview-title text-caption mb-1">Rack/Bay/Location</h6>
    <v-card outlined min-width="300px">
      <v-card-text class="d-flex align-center py-2">
        <v-checkbox
          v-model="checkbox"
          label="0 Selected"
          hide-details="auto"
          class="mt-0 pt-0"
        ></v-checkbox>
      <v-spacer></v-spacer>
      <v-btn text small class="btn-link px-1">
        <v-icon small class="mr-1">mdi-file-tree</v-icon>
        Expand all
        <!-- toggle button - Expand all or Fold all -->
      </v-btn>
    </v-card-text>
    <v-divider></v-divider>
      <v-treeview selectable selected-color="primary" item-disabled="locked" :items="items"></v-treeview>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Tree',
  components: {

  },
  data: () => ({
    items: [
      {
        id: 1,
        name: '104 :',
        locked: true,
        children: [
          { id: 2, name: '01' },
          { id: 3, name: 'A' },
          { id: 4, name: '01' },
        ],
      },
      {
        id: 5,
        name: '105 :',
        children: [
          {
            id: 6,
            name: '01 :',
            children: [
              {
                id: 7,
                name: 'A',
                locked: true,
                children: [
                  { id: 8, name: '01' },
                  { id: 9, name: '02' },
                ],
              },
            ],
          },
          {
            id: 10,
            name: '02 :',
            children: [
              {
                id: 11,
                name: 'B :',
                children: [
                  { id: 12, name: '01' },
                  { id: 13, name: '02' },
                  { id: 14, name: '03' },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 15,
        name: '106',
        children: [
          { id: 16, name: '01', locked: true },
          { id: 17, name: '02', locked: true },
          { id: 18, name: '03', locked: true },
        ],
      },
      {
        id: 19,
        name: '107',
        children: [
          {
            id: 20,
            name: '01',
            children: [
              { id: 21, name: 'A' },
              { id: 22, name: 'B' },
              { id: 23, name: 'C' },
            ],
          },
          { id: 24, name: '02' },
          { id: 25, name: '03' },
        ],
      },
    ],
  }),
  computed: {
  }
}
</script>