export default {
    title: {},

    label: {
        userId: 'User ID',
        password: 'Password',
        rememberId: 'Remember ID',
    },

    btn: {
        signin: 'Signin',
    },

    message: {
        forgetIdPw: 'Forget ID/Password?',
    },
};
