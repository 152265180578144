export default {
    title: {
        sampleTitle: '샘플 타이틀',
    },

    label: {},

    btn: {},

    message: {
        sampleMessage: '샘플 메시지입니다.',
    },
};
