// @ is an alias to /src
import DashboardBulletinList from '@/views/dashboard/DashboardBulletinList.vue'
import DashboardNotiList from '@/views/dashboard/DashboardNotiList.vue'

export default {
  name: 'AlarmLayout',
  components: { 
    DashboardBulletinList, DashboardNotiList
  },
  data: () => ({
    tabAlarm: null,
  }),
}